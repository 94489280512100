import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Login from '../components/Login';
import Spinner from '../components/Spinner';
import VerifyMyEmail from '../components/VerifyMyEmail';




// checks if authenticated
function withAuth(MyComponent) {

    return function isAuthComponent() {

        const context = useContext(AppContext);

        const [ state, setState ] = useState('login');
        const [ loading, setLoading ] = useState(true);


        useEffect(() => {
            const token = localStorage.getItem('feta-auth-token');
            if (!token) {
                setState('login');
                setLoading(false);
                return;
            } 

            // CHECK MY TOKEN
            // PATCH 07-05-2021
            axios.get(
                `${context.API}/check-my-token/`,
                { headers: {...context.data.headers} }
            ).then(
                res => {
                    localStorage.setItem("feta-user-email", res.data)
                    setState('auth');
                    setLoading(false);
                }
            ).catch(
                e => {
                    console.log(e);
                    setState('login');
                    setLoading(false);
                }
            )
        }, [])

        // spin while waiting decision
        if (loading) {
            return <Spinner />
        }
        
        // if no token -> go to login page
        if (state === 'login') {
            return <Login />
        }

        return <MyComponent />;
    }
}
export default withAuth;

