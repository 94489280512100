import React, { useContext, useEffect, useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Divider, Form, Input, message, Radio,  Space } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../AppContext';
import Navigation from './Navigation';
import ThemedButton from './ThemedButton';



// create new substance form
function Substance() {
    const [error, setError] = useState(false);
    const [oelType, setOELtype] = useState('0')
    const context = useContext(AppContext);
    const role = context.data.roleObj;
    const [form] = Form.useForm();
    let history = useHistory();
    


    // substance id from requested url
    let { id } = useParams();
    id = parseInt(id);



    // load substance data from context
    let substance = {};
    if (id !== 0) { 
        substance = {...context.data.substances.find(o => o.id === id)};
        if (substance.oel) {
            if (substance.oel.toString()[0] === '.') {
                substance.oel = `0${substance.oel}`;
            }
        }
    } else {
        substance.private = true;
    }

    // needed to hide or show oel type input
    // for ferring only
    useEffect(() => {
        setOELtype(substance['oel_type']);
    }, [])


    // on form submit event
    const handleFinish = values => {
        // update existing substance
        if (id !== 0) {
            axios.put(
                `${context.API}/substances/${id}/`,
                {...values, id: id},
                {headers: {...context.data.headers}}
            )
            .then(() => {
                message.success("Substance data updated");
                context.update();
                history.push('/substances/');
            })
            .catch(() => message.error("An error occurred!"))
            return;
        }

        // create new substance
        axios.post(
            `${context.API}/substances/`,
            {...values, id: id},
            {headers: {...context.data.headers}}
        ).then(
            () => {
                message.success("New substance created");
                context.update();
                history.push(`/substances/`);
            }
        ).catch(
            () => message.error("An error occurred!")
        )
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    


    const CustomNavigation = (
        <div>
            <Link to="/substances">
                <ThemedButton type="link-outlined" label="Cancel" />
            </Link>
            <Divider type="vertical"/>
            <ThemedButton
                type="primary"
                label={<span><SaveOutlined/> Save</span>}
                onClick={form.submit}
            />
        </div>
    )

    // if a submit or another error occurs
    // this component will display it
    let Error = <div />;
    if (error == 'submit') {
        Error = (
            <div style={{ marginBottom: 15 }}>
                <Alert
                    type="error"
                    message="Submission error"
                    closable
                    showIcon
                    description="Please make sure that you have no empty mandatory fields (i.e. substance name and OEL)"
                />
            </div>
        )
    }
  





    return(
        <div>
            <Navigation 
                title="New Substance"
                subTitle="Substance Data Form"
                customNavigation={CustomNavigation}
            />

            { Error }

            <Form 
                {...context.data.scaling}
                form={form}
                initialValues={{ 
                    name: '',
                    cas: '',
                    oel: '',
                    info: '',
                    oel_type: '',
                    oel_info: '',
                    oel_unit: "ug/m3", 
                    private: id===0 ? true : substance.private,
                    ...substance 
                }}
                onFinishFailed={() => setError('submit')}
                onFinish={handleFinish}
                autoComplete="off"
            >
                <Form.Item name="name" label="Substance name"
                    rules={[
                        { required: true, message: "Name is required" },
                        { min: 3, message: "Too short" },
                        { max: 50, message: "Too long" }
                    ]}
                    required
                >
                    <input minLength={3} maxLength={50} />
                </Form.Item>

                <Form.Item name="cas" label="CAS-Nr.">
                    <input />
                </Form.Item>
               

                <Form.Item label="Occupational Exposure Limit (OEL)" required>
                    <Input.Group compact>
                        <Form.Item name="oel"
                            noStyle
                            rules={[{ required: true, message: "OEL required" }]}
                        >
                            <input 
                                type="number"
                                min={0.0001}
                                step={0.0001}
                            />
                        </Form.Item>
                        <Form.Item name="oel_unit" noStyle>
                            <select style={{ width: "30%" }}>
                                <option value="ng/m3">ng/m3</option>
                                <option value="ug/m3">&mu;g/m3</option>
                                <option value="mg/m3">mg/m3</option>
                            </select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item name="oel_type" label="OEL type">
                    <select onChange={e => setOELtype(e.target.value)}>
                        <option value="0"></option>
                        {
                            context.data.oelList.map(
                                item => (
                                    <option key={item.value} value={item.value}>
                                        { item.label }
                                    </option>
                                )
                            )
                        }
                    </select>
                </Form.Item>

                
                {
                    oelType === 'other'
                    ? <Form.Item name="oel_info" label="Other OEL">
                        <input placeholder="Please specify OEL type" />
                    </Form.Item>
                    : <div />
                }

                {
                    role.user === substance.user  | id === 0 ?
                        <Form.Item
                            name="private"
                            label="Private*"
                            normalize={() => !substance.private}
                        >
                            <input type="checkbox" defaultChecked={substance.private} />
                        </Form.Item>
                    : <div />
                }


                <Form.Item label="Additional information" name="info">
                    <textarea 
                        rows={5}
                        placeholder="Information regarding supplier, amount, other toxicological values, etc." 
                    />
                </Form.Item>
            </Form>

            <p>* Private substance means that it is only visible to your. 
                Making it non-private, it becomes visible and editable by the users who are part of the same site.
            </p>
        </div>
    )
}
export default Substance;