import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';




// Control strategy 5
// double barrier protection
// both Exposure and OEL must be in micro in props
function CS5(props) {
    const context = useContext(AppContext);
    const exposure = props.exposure;
    const oel = props.oel;
    
    // states
    const [ lc, setLc ] = useState({ lc1: [], lc2: [] });


    // constants
    const trBorder = { borderTop: '1px solid rgba(220,220,220,0.2)' };
    const labels = require('../json/labels.json');
    const controls = require('../json/controls.json').find(o => o.lc === 'dbc').controls;
    const controlButtons = [
        "c-low",
        "c-med",
        "c-high",
        "cont",
        "gb",
        "gb-v",
        "gb-low",
        "gb-med",
        "gb-high"
    ]


    useEffect(() => {
        // on load and on change of selected lc in first column
        let lc1 = [];
        let lc1Selected = false;

        // first we check which primary control may result in no risk
        // considering that the secondary control is the most efficient
        // the one with score equal to 0.0001
        for (let key in controls) {
            let risk = (controls[key] * 0.0001 * exposure / oel) > 1;
            
            if (!risk) {
                lc1.push(`${key}-1`);
            }

            if (!risk && !lc1Selected) {
                lc1Selected = key;
            } 
        }

        // if no lc1 -> no need to go further
        if (!lc1Selected) {
            return;
        }

        setLc({
            ...lc,
            lc1: lc1,
            selected: lc1Selected
        })
    }, [props])



    // on change of lc selected
    useEffect(() => {
        // secondary controls that will be green on load
        // depend on the selected LC-1
        if (!lc.selected) return;

        let lc2 = [];
        let lc2Selected = false;
        let lcExposure = 0;

        for (let key in controls) {
            let risk = (controls[key] * controls[lc.selected] * exposure / oel) > 1;
            
            if (!risk) {
                lc2.push(`${key}-2`);
            }

            if (!risk && !lc2Selected) {
                lc2Selected = key;
                lcExposure = controls[key] * controls[lc.selected] * exposure;
            } 
        }
        setLc({
            ...lc, 
            lc2: lc2,
            selected2: lc2Selected,
            exposure: lcExposure
        });
    }, [lc.selected])


    // when lc2 selected changed
    // update exposure
    useEffect(() => {
        if (!lc.selected2) return;

        let lcExposure = controls[lc.selected2] * controls[lc.selected] * exposure;
        setLc({ ...lc, exposure: lcExposure });
    }, [lc.selected2])


    return(
        <div>
            <table>
                <thead>
                    <tr style={trBorder}>
                        <th colSpan="3" style={{ textAlign: 'center', width: '30%' }}>
                            Control strategy 5 - Contained
                        </th>
                    </tr>
                    <tr style={trBorder}>
                        <th colSpan="3" style={{ textAlign: 'center' }}>
                            Local Control Concept - Duble Barrier Containment
                        </th>
                    </tr>
                    <tr style={trBorder}>
                        <th colSpan="3">Local Control Options</th>
                    </tr>
                </thead>

                <tbody>
                    <tr style={trBorder}>
                        <td colSpan="3">
                            Control Strategy 5 requires application of the double barrier containment principle.  The IH Control Strategies Tool allows the user to combine different forms of containment to understand the impact on modelled exposure with simple clicks; however, a combination of containment should still be selected based on applicability to the process being assessed.  For this reason, no specific local controls are recommended for Control Strategy 5 and final decisions on local control options should be made by consulting experts in the fields of industrial hygiene,  engineering and pharmaceutical containment technologies.
                            <br/><br/>Note: Recirculation of extracted air into workplace is not permitted.
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td style={{ width: "40%" }}><strong>Primary barrier</strong></td>
                        <td style={{ width: "40%" }}><strong>Secondary barrier</strong></td>
                        <td><strong>Tool output</strong></td>
                    </tr>

                    <tr style={trBorder}>
                        <td>
                            {
                                controlButtons.map(
                                    (item, inx) => (
                                        <div key={inx}>
                                            <ThemedButton
                                                type={ lc.lc1.includes(`${item}-1`) ? 'success-outline' : 'danger-outline' }
                                                className={lc.selected === item ? 'control-selected' : ''}
                                                disabled={ !lc.lc1.includes(`${item}-1`) }
                                                label={ labels[item] }
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    minHeight: 50,
                                                    marginBottom: 2 
                                                }}
                                                onClick={() => setLc({...lc, selected: item})}
                                            />
                                        </div>
                                    )
                                )
                            }
                        </td>
                        <td>
                        {
                            controlButtons.map(
                                (item, inx) => (
                                    <div key={inx}>
                                        <ThemedButton
                                            type={ lc.lc2.includes(`${item}-2`) ? 'success-outline' : 'danger-outline' }
                                            className={lc.selected2 === item ? 'control-selected' : ''}
                                            disabled={ !lc.lc2.includes(`${item}-2`) }
                                            label={ labels[item] }
                                            style={{
                                                width: '100%',
                                                textAlign: 'left',
                                                minHeight: 50,
                                                marginBottom: 2 
                                            }}
                                            onClick={() => setLc({...lc, selected2: item})}
                                        />
                                    </div>
                                )
                            )
                        }
                        </td>
                        <td 
                            style={{ textAlign: 'center'}}
                            className={ lc.lc1.length === 0 ? 'red-td' : 'green-td' }
                        >{
                            <div>{
                                lc.exposure 
                                ? context.functions.rounding(lc.exposure)
                                : context.functions.rounding(exposure)} &mu;g/m<sup>3</sup>
                            </div>
                        }
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td colSpan="3">
                            <strong>Connection/ Processing / Transfer Points</strong>
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>High Specification</strong></td>
                        <td colSpan="2">
                            The material transfer is entirely enclosed with high containment valves (e.g. split butterfly valves and direct couplings, consisting of alpha-beta parts which fit together to allow unlocking of the valve). At the end of the material transfer the two halves are separated, forming a seal on both the process equipment and the material container. The system is designed to minimize the surface area which can contact the material or pairs of valves with wash space between them. In addition, continuous liner systems should be secured with multiple o-rings and/or clamps and a higher-grade crimping device with proven containment performance should be used.
                        </td>
                    </tr>
                    <tr style={trBorder}>
                        <td colSpan="3">
                            <strong>Facility Design Recommendations</strong>
                        </td>
                    </tr>
                    <tr style={trBorder}>
                        <td><strong>Workspace Layout</strong></td>
                        <td colSpan="2">
                            <p>
                                Segregate work area from adjacent spaces by physical barrier envelope (walls, floor, ceiling) and airlocks i.e. dedicated room.
                            </p><p>
                                Surfaces: Appropriate for type of hazardous substances to be handled. Highly cleanable with hard, washable, coved or rounded corners, and crevice free.                            </p><p>
                            </p><p>
                                Piping: Minimize exposed piping and mechanisms to facilitate cleaning. Consider separate mechanical room for pipe routes.
                            </p><p>
                                Light Fixtures: Gasketed or sealed so that the separation of the room from the ceiling space is maintained during lamp replacement in CS5 and should be considered for CS4.                            </p>
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>Airlocks</strong></td>
                        <td colSpan="2">
                            Separate entry and exit airlocks for both material and personal required.  
                            <br/><br/>
                            Interlocked doors required.
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>Pressure cascade</strong></td>
                        <td colSpan="2">
                            The purpose of room pressure cascade concept is to prevent the health hazard from moving into adjacent workspaces or common use corridors yet the EHS and GMP concepts can contradict. Define with EHS/IH and QA experts. 
                            <br/><br/>
                            A pressure differential of 7.5 Pa to 15 Pa is recommended. The airlock pressure should be between that of the adjacent areas. Airlocks can be designed as cascade, positive (bubble), or negative (sink). Combinations of these three types may be needed to meet GMP and exposure control.
                            <br/><br/>         
                            Monitoring of the pressure cascades implemented for CS4 and CS5 with alarm to notify personnel in the event system operating out of defined range.
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>HVAC - replacement filter</strong></td>
                        <td colSpan="2">
                            Filter replacement must be a safe change system located for access from inside the secondary containment area contained technical room, or other type of duct-filter isolation system.
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>HVAC - recirculation</strong></td>
                        <td colSpan="2">
                            No recirculation from process area into other process and non-process areas.
                        </td>
                    </tr>

                    <tr style={trBorder}>
                        <td><strong>HVAC - Exhaust air from rooms</strong></td>
                        <td colSpan="2">
                            <br/><br/>Filters installed at the exhaust duct from the barrier or room to reduce potential contamination from entering exhaust duct. 
                            <br/><br/>Choice of filter type must be appropriate for the substance particle size and health hazard.
                            <br/><br/>A separate exhaust duct not part of general building exhaust should be considered for CS4.
                            <br/><br/>Exterior building exhaust emission designed so no re-entrainment to building air supply.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default CS5;