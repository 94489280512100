import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';




export default function UserRolesInfo() {
    return (
        <div>
            <Navigation title="Information" subTitle="User roles"/>
            
            <div>
                <div>
                    <p style={{ fontSize: 26 }}><strong>Sites and User Roles</strong></p>
                    <p><strong>(Upgrade: March 2022)</strong></p>
                    <p>The March upgrade brings two new features, i.e. Sites and User roles. This is integrated in the “Site management” section on the homepage. These two features allow the users to work together on the substance data entry and exposure/controls evaluation. Data entered by one user can be visible to other user who can then edit these data. For example, if one user entered hundred of substances, another user can reuse these data to create his exposure scenarios. The goal is to make this exposure software more efficient by reducing redundancy.&nbsp;</p>
                    <p style={{ fontSize: 22 }}><strong>What is Site?</strong></p>
                    <p>Site is a virtual group of two or more users who voluntarily join to make their work more efficient by splitting different tasks related to data entry and evaluation. The site-users (i.e. the users who joined the same site) are not obliged to share their data with the others. Any data created by a user is by default private.</p>
                    <p><strong>Private and non-private (site data) data</strong></p>
                    <p>By default, all data (i.e. substances and exposure scenarios) created by a user (both non-site and site-user) are private. When a user joins a site, previously entered data remain private. This means that these data are neither visible nor editable by another user except the user who created them. This is specified by a checkbox item (i.e. “Private”) displayed on the forms used to create a new substance or a new scenario. To make a data record (e.g. substance) visible to the other site members, it is necessary to uncheck this item, which will make this data instance accessible to the other users of the same site (i.e. non-private or site data). Please note that this data <strong>is not</strong> accessible by the users of other sites. Each site-data record is clearly labelled by the word “Site” in the table listing all the data available to a user. If the data is private, instead of “Site” the column “Owner” includes the word “User”. A private data record can migrated to non-private or vice-versa at any time.</p>
                    <p><strong>Important:</strong> Although a non-private data record is visible and editable by the other users who are members of the same site, only the user who created the record can delete it.</p>
                    <p><strong>How to create a site?</strong></p>
                    <p>A site can be created by any user. It is necessary to access “Site management” on the homepage and send a request to create a new site. This request is treated by the App manager and can be either approved or rejected. A site can be also requested by sending an email directly to the App manager who then can create a site for you and assign the “Site manager” role to you.</p>
                    <p>When creating a new site, the user must specify desired site name and location. Site name must be unique (i.e. two sites cannot have the same name). For location, if the users of the site do not share the same location, the requesting user should enter his physical location (e.g. Tokyo). Both Site name and Location should be as short as possible but not shorter than five characters. For both name and location, it is recommended to be single words, if possible (e.g. MyExposureSite1 in Tokyo).&nbsp;</p>
                    <p style={{ fontSize: 22 }}><strong>User Roles</strong></p>
                    <p>The new version of your software now defines three user roles:</p>
                    <ol>
                    <li>App manager</li>
                    <li>Site manager</li>
                    <li>Regular user (site and non-site user)</li>
                    </ol>
                    <p>By default, all users who have opened their accounts before the March upgrade are Regular non-site users. Each of these roles have different permissions regarding the site and user management.</p>
                    <p><strong>App Manager</strong></p>
                    <p>Only one user of your company has this role. The App manager can create, edit and delete a site as well as assign the “Site manager” role to a regular non-site user. App manager receives the requests from Regular non-site users who want to create a site and become its Site managers. The requests can be received either by email (App manager access “Site management” and creates a site) or thought the software (the requests are listed on the “Site management” page.. The number of received requests through the software is highlighted on the “Manage” button in the “Site management” section on the homepage. The App manager then either approves their requests or rejects them. The App manager (<strong>must!</strong>) keep eye on who wants to create a site and become its Site manager. It is important to keep in mind that only one user should have this role within a site team. The App manager itself cannot be part of any site.&nbsp;</p>
                    <p><strong>Site Manager</strong></p>
                    <p>Any Regular non-site user can send a request to become a Site manager through the software or by sending an email directly to the App manager. This can be achieved by sending the request on the “Site Management” section (Click on the “Manage” button within this section on the homepage). Within a group, only one user can have the “Site manager” role. It is highly recommended to discuss within the team who should apply for this role. A site can be created by any group of people who have a need to share any data within the software. It is not strictly intended to be a group of people who physically work at the same site in your company (e.g. Manufacture of API X1 in Tokyo). It can be any group of people who exchange some data.&nbsp;</p>
                    <p>After the request sent to App manager is approved, the role changes to “Site manager”. A Site manager should then inform the users who should join the created site to send the requests to join the site as site-users. Each request received from Regular non-site (yet) users are listed on the “Site management” page. The Site manager then approves or rejects them.</p>
                    <p><strong>Regular User</strong></p>
                    <p>Any user who is not App/Site manager has the “Regular user” role. A regular user, as explained before, can apply to become a “Site manager” or a site-user. The former is explained in the section above. The latter is a “Regular user” who applied to join a site and his request was approved by the Site manager. When a Site manager creates a site, he must inform the users via email who should join the site. The regular users then apply to join the site on the “Site Management” page (i.e. section “Join an existing site”). When applying to join, the Regular users must enter the correct email address of the Site manager who invited them. A simple explanation (e.g. copy-paste of the email content received from the Site manager) why the user wants to join must be provided. This request is then forwarded to the corresponding Site manager who verifies the request. After the Site manager approved the request, the non-site Regular user becomes Regular site-user.&nbsp;</p>
                    <p><br /></p>

                    <p>
                        For more information and support, please <Link to="/contact">Contact support</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}