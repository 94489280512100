import { Alert, Divider, Form, message } from 'antd'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext'
import Spinner from './Spinner';
import ThemedButton from './ThemedButton';



/*
    Upgrade March 2022 (User roles)
    * this component is rendered as a part of SiteManagement.js
    * this component does the following:
    *   allows regular users to send request to manager for becoming site manager
    *   allows regular user to become part of a site (sends request to site manager)
    *   allows regular users to leave a site
*/
export default function RegularUserPage() {

    const [state, setState] = useState({});
    const context = useContext(AppContext);
    const role = context.data.roleObj;
    const api = context.API;
    const headers = context.data.headers;

    // While processing a request
    if (state.loading) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    // If site member
    if (role.status === 'approved') {
        return (
            <div>
                 <Alert
                    closable
                    onClick={() => {
                        axios.get(`${api}/reset-role-status/`, {headers: headers})
                        .then(() => console.log("Role status reset"))
                        .catch(()  => console.log("Role status reset failed"))
                    }}
                    style={{ marginBottom: 25 }}
                    showIcon
                    type="success"
                    message="Congratulations"
                    description="Your status have been changed by a Site manager.
                                You are now a member of the site you applied to join."
                />

                <div>
                    <strong>My site:</strong> {context.data.site.name} ({context.data.site.location})
                </div>
            </div>
        )
    }

    if (role.site) {
        return(
            <div>
                <strong>My site:</strong> {context.data.site.name} ({context.data.site.location})
            </div>
        )
    }

    // if previous request rejected
    if (role.status === 'rejected') {
        return (
            <div>
                <Alert
                    type="error"
                    message="Request rejected"
                    description="   Your previous request sent to Site manager / App manager is rejected. If necessary,
                                    you can send another request to become 'Site manager' or to join a site
                                    with the 'Regular user' role. Please avoid sending unlimited number of 
                                    requests. If you think that your request should have been accepted, it 
                                    is recommended to discuss this with your Site manager / App manager
                                    via email."
                    showIcon
                />
                <br/>

                <div>
                    <ThemedButton
                        type="primary"
                        label="(Re)submit"
                        onClick={() => {
                                axios(`${api}/reset-role-status/`, {headers: headers})
                                .then(res => res.status === 200 ? context.update() : message.warning("Something did not work"))
                                .catch(() => message.error("Failed"))
                            }
                        }
                    />
                </div>
            </div>
        )
    }


    // If status is pending (i.e. a request already submitted)
    // Show alert and disable possiblity to send other requests
    if (role.status === 'pending' || state.pending) {
        return (
            <div>
                <Alert
                    type="warning"
                    message="Request pending"
                    description="   A request has been successfully sent.
                                    You cannot send other requests while your current request is pending."
                    showIcon
                />
            </div>
        )
    }

    return (
        <div>
            <div>
                <Divider className={context.data.company}>Become Site manager</Divider>
                <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', border: '1px solid silver', padding: 10 }}>
                    Before proceeding to the request, please read <Link to="/user-roles-info">the documentation</Link>
                </p>
                <Form
                    name="siteManagerRequestForm"
                    {...context.data.scaling}
                    style={{ marginTop: 25 }}
                    onFinish={values => {
                        setState({ loading: true });
                        values.requestedTo = 'manager';
                        axios.post(`${api}/role-request/`, {...values}, {headers: {...headers}})
                        .then(res => {
                            if (res.status === 208) {
                                setState({ alreadyExists: true });
                                message.warning("Another site is already using this name! Select another")
                            } else if (res.status === 201) {
                                message.success("Request sent!");
                                setState({ pending: true });
                                context.update();
                            }
                        } )
                        .catch( () => message.error('Failed to process your request'))
                    }}
                >
                    <Form.Item
                        required
                        name='name'
                        label='Desired site name'
                        rules={[{required: true,message:'Site name is required'}, {min: 2, message: 'Too short'}]}
                    >
                        <input max={25} />
                    </Form.Item>
                    <Form.Item
                        required
                        name='location'
                        label='Site location'
                        rules={[{required: true,message:'Site location is required'}, {min: 3, message:'Too short'}]}
                    >
                        <input max={25} placeholder="e.g. Tokyo" />
                    </Form.Item>
                    <Form.Item
                        required
                        name='message'
                        label='Cover letter'
                        rules={[
                            {required: true,message:'Justification is required'},
                            {min: 25, message:'At least 25 characters must be included'}]
                        }
                    >
                        <textarea placeholder='Justification for becoming a site manager' rows={8} />
                    </Form.Item>
                    <Form.Item {...context.data.tailedScaling} style={{ textAlign: 'right' }}>
                        <ThemedButton
                            type='primary'
                            label='Send request'
                            buttonType='submit'
                        />
                    </Form.Item>
                </Form>
            </div>

            <div>
                <Divider className={context.data.company}>Join an Existing Site</Divider>
                <div>
                    <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', border: '1px solid silver', padding: 10 }}>
                        Before proceeding to the request, please read <Link to="/user-roles-info">the documentation</Link>
                    </p>

                    <Form
                        name="joinSiteForm"
                        {...context.data.scaling}
                        style={{ marginTop: 25 }}
                        onFinish={values => {
                            setState({ loading: true });
                            values.requestedTo = 'site';
                            axios.post(`${api}/role-request/`, {...values}, {headers: {...headers}})
                            .then(res => {
                                if (res.status === 204) {
                                    setState({ siteManagerNotFound: true });
                                    message.warning("Site manager with this email not found");
                                } else if (res.status === 201) {
                                    message.success("Request sent!");
                                    setState({ pending: true });
                                    context.update();
                                }
                            } )
                            .catch( () => message.error('Failed to process your request'))
                        }}
                    >
                        <Form.Item
                            required
                            name='manager'
                            label="Site manager's email"
                            rules={[{required: true,message:'The email is required'}]}
                        >
                            <input type="email" />
                        </Form.Item>
                        <Form.Item
                            required
                            name='message'
                            label='Cover letter'
                            rules={[
                                {required: true,message:'Site name is required'},
                                {min: 25, message:'At least 25 characters must be included'}]
                            }
                        >
                            <textarea placeholder='Please explain briefly why you want to join this site' rows={8} />
                        </Form.Item>
                        <Form.Item {...context.data.tailedScaling} style={{ textAlign: 'right' }}>
                            <ThemedButton
                                type='primary'
                                label='Request to join'
                                buttonType='submit'
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}