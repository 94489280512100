import React, { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Divider, Form, message, Space
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import ThemedButton from './ThemedButton';
import Navigation from './Navigation';
import { AppContext } from '../AppContext';
import axios from 'axios';
import Spinner from './Spinner';




// fields that after change will reset conditioned
const resetLinks = {
    state: ['vp', 'dustiness', 'moisture', 'viscosity'],
    activity: ['det1', 'det2', 'det3']
}




// ART input parameters
function Task() {

    // states
    const [ loaded, setLoaded ] = useState(false);
    const [ values, setValues ] = useState({ });
    const [ incompleteTask, showIncompleteTask ] = useState(false);

    // constants
    const context = useContext(AppContext);
    let history = useHistory();

    let { scenario } = useParams();
    let { task } = useParams();
    const taskID = parseInt(task);
    const scenarioID = parseInt(scenario);

    scenario = {...context.data.scenarios.find(o => o.id === scenarioID)};
    task = task === 0 ? {} : {...context.data.tasks.find(o => o.id === taskID)};
    const stype = scenario['scenario_type'];
    const myjson = require(stype === 0 ? '../json/ihcs.json' : '../json/exposure.json');
    const [ form ] = Form.useForm();
    let substance = {}

    try {
        substance = context.data.substances.find(o => o.id === scenario.substance);
        substance = {
            state: substance.state,
            vp: substance.vp,
            dustiness: substance.dustiness,
        };
    } catch (e) {
        console.log("substance is private")
    }

    

    const initialValues = {
        name: 'My new task',
        duration: 480,
        nf: true,
        ff: false,
        ...task,
        ...substance,
        ...JSON.parse(task.art ? task.art : '{}')
    }
 
    // links
    const navCancelLink = `/scenario/stype=${stype}-es=${scenarioID}`;
    const postURL = `${context.API}/tasks/`;
    const putURL = postURL + taskID + "/";
    

    useEffect(() => {
        setValues(initialValues);
        setLoaded(true);
    }, [])


    // when allert incomplete scenario shown
    useEffect(() => {
        if (incompleteTask){
            message.warning("Task not saved!")
        }
    }, [incompleteTask])

    if (!loaded) return <Spinner />

  

    // handle form change and values update
    const handleFormChange = (chVal, allVals) => {
        
        for (let key in resetLinks) {
            // first find which to reset
            if (key in chVal) {
                let resetFields = resetLinks[key];

                // now modify allVals accoring resetFields
                for (let i in resetFields){
                    allVals[resetFields[i]] = '';
                }
            }
        }
        form.setFieldsValue(allVals)
        setValues(allVals);
        form.getFieldValue('det1')
    }





    // creates field
    const NewField = field => {
        // based on conditions field hidden/shown (i.e. (non)rendered)
        let visible = true;
        if (field.conditions) {
            for (let i in field.conditions) {
                visible = eval(field.conditions[i]);
                if (!visible) return;
            }
        }
    

        switch(field.type) {
            case "checkbox": 
                return(
                    <Form.Item 
                        key={field.name}
                        name={field.name}
                        {...context.data.tailedScaling}
                        normalize={() => !values[field.name]}
                    >
                        <Space>
                            <input 
                                type="checkbox"
                                defaultChecked={values[field.name] !== undefined ? values[field.name] : field.default} 
                            />
                            <label className="checkbox-label">
                                {field.label}
                            </label>
                        </Space>
                    </Form.Item>
                )
            case "number": 
                return(
                    <Form.Item 
                        required
                        label={field.label}
                        key={field.name}
                        name={field.name}
                        rules={[{required: true, message: "This field is obligatory"}]}
                    >
                        <input type="number" 
                            min={field.min} max={field.max} 
                            step={field.step} 
                        />
                    </Form.Item>
                )
            case "text":
                return(
                    <Form.Item 
                        label={field.label}
                        key={field.name}
                        name={field.name}
                    >
                        <textarea rows="5" type="text" />
                    </Form.Item>
                )
            
            default:
                let options = field.options;
                if (field.link) {
                    // dependent field
                    options = field.options[values[field.link]];
                    if (!options || options.length === 0) return <div key={field.name} />
                }
                
                // if field changes, but the conditioned keep old value
                // we must reset it to false
                let keyList = [];
                for (let i in options) {
                    if (options[i].options) {

                        // suboptions (optgroups where applied)
                        let subOptions = [...options[i].options];
                        for (let k in subOptions) {
                            keyList.push(subOptions[k].value);
                        }

                    }
                    // outside optgroups
                    keyList.push(options[i].value);
                }

                // PATCH OPTIONS BUG (REMAINS SELECTED ALTHOUGH NO APPROPRIATE OPTION)
                let reset = !( keyList.includes(values[field.name]) );
                let disabled = field.name in values;
                disabled = !reset;
                
                return (
                    <Form.Item 
                        required
                        label={field.label}
                        key={field.name}
                        name={field.name}
                        rules={[ {required: true, message: "This field is obligatory"} ]}
                    >
                        <select>
                            <option key="empty" value={false} disabled={disabled}></option>
                            {options.map(
                                option => (
                                    option.options ?
                                    <optgroup label={option.label} key={option.value}>
                                        {
                                            option.options.map(
                                                subopt => (
                                                    <option value={subopt.value} key={subopt.value}>
                                                        {subopt.label}
                                                    </option>
                                                )
                                            )
                                        }
                                    </optgroup>
                                    : <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                )
                            )}
                        </select>
                    </Form.Item>
                )
        }
    }






    // save button will return values from task and art forms
    // it is the button in navigation component
    return(
        <div>
            <Navigation
                title="Activity task"
                subTitle="Exposure inputs"
                customNavigation={
                    (<div>
                        <ThemedButton
                            type="link-outline" htmlType="submit" 
                            label="Cancel"
                            onClick={()=>history.push(navCancelLink)}
                        />
                        <Divider type="vertical"/>
                        <ThemedButton 
                            type="primary" htmlType="submit"
                            label={<span><SaveOutlined/> Save</span>}
                            onClick={form.submit}
                        />
                    </div>)}
            />

            <div>
                <Alert
                    style={{ display: incompleteTask ? 'flex' : 'none', marginBottom: 25 }}
                    type="error"
                    message="Task incomplete"
                    description="You cannot save task if some fields are empty! Please scroll down and check if you have entered values for all fields."
                    showIcon
                />
            </div>

            <main>
                <Divider className={context.data.company} type="horizontal">
                    Task name & duration
                </Divider>
                <Form 
                    name="task"
                    {...context.data.scaling}
                    initialValues={initialValues}
                    form={form}
                    onFinishFailed={() => showIncompleteTask(true)}
                    onFinish={
                        values => {
                            let art = {};
                            let obj = {scenario: scenarioID};

                            for (let key in values) {
                                if (['name', 'duration'].includes(key)) {
                                    obj[key] = values[key];
                                } else { art[key] = values[key] }
                            }
                            obj.art = JSON.stringify(art);

                            // post or put request depending on link (task id)
                            axios({
                                url: !taskID ? postURL : putURL,
                                method: !taskID ? 'post' : 'put',
                                data: obj,
                                headers: {...context.data.headers}
                            }).then(
                                () => {
                                    let msg = "New task created";
                                    if (!!taskID) msg = "Task updated";
                                    message.success(msg);
                                    context.update();
                                    history.push(navCancelLink);
                                }
                            ).catch(
                                () => message.error("ERROR OCCURED")
                            )
                        }
                    }
                    onValuesChange={(ch, all) => handleFormChange(ch, all)}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        required
                        rules={[
                            {required: true, message: "Task name is required"},
                            {min: 3, message: "Too short name"},
                            {max: 50, message: "Too long name"}
                        ]}
                    >
                        <input type="text" minLength={3} maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        label="Duration, min"
                        required
                        rules={[
                            { required: true, message: "Duration cannot be empty"}
                        ]}
                    >
                        <input type="number" step={1} min={5} max={480} />
                    </Form.Item>


                    <Divider className={context.data.company} type="horizontal">Substance</Divider>
                    {myjson.map(field => field.sector === "substance" ? NewField(field) : "")}
                    <br/>
                    <Divider className={context.data.company} type="horizontal">Activity</Divider>
                    {myjson.map(field => field.sector === "activity" ? NewField(field) : "")}
                    <br/>
                    <Divider className={context.data.company} type="horizontal">General Ventilation</Divider>
                    {myjson.map(field => field.sector === "controls" ? NewField(field) : "")}
                </Form>
            </main>
        </div>
    )
}
export default Task;




