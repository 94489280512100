import { CalculatorOutlined, ClockCircleOutlined, CloseOutlined, CloudUploadOutlined, ControlOutlined, DeleteOutlined, EditOutlined, FilePdfOutlined, PrinterOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Col, Divider, Empty, Form, message, Popconfirm, Row } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import MeasuredScenario from './MeasuredScenario';
import Navigation from './Navigation';
import ThemedButton from './ThemedButton';



// scenario contains
// * scenario identification (name, substance...)
// * Tasks and corresponding data (ART input parameters)
// allows navigation and editing of tasks

function Scenario() {
    const [modal, setModal] = useState({});
    const context = useContext(AppContext);
    const role = context.data.roleObj;
    let { stype } = useParams(); // 1 is for EA, 0 for IHCS
    let { id } = useParams();
    stype = parseInt(stype);
    id = parseInt(id);
    let scenario = {...context.data.scenarios.find(o => o.id === id)};

    const labels = require('../json/labels.json');
    
    let measured = [];
    if (scenario.name) {
        measured = JSON.parse(scenario.measured)
    }

    if (id === 0) {
        scenario.private = true;
    }

    let history = useHistory();
    const [form] = Form.useForm();
    const tasks = context.data.tasks.filter(o => o.scenario === id);

    let duration = 0;
    for (let i in tasks) {
        duration += tasks[i].duration;
    }

    // check if substance is accessible to this user
    let isSubstanceAccessible = false;
    try {
        let substance = context.data.substances.find(o => o.id === scenario.substance);
        if (substance) {
            isSubstanceAccessible = true;
        }
    }catch(e) {
        console.log("Scenario.js: substance not accessible");
    }



    // submit complete form below
    const handleSubmit = (values, goToTask) => {
        if (!isSubstanceAccessible && id!==0) {
            message.warning("Not allowed to modify. Selected substance is not accessible to you")
            return;
        }
        axios({
            method: id !== 0 ? 'put' : 'post',
            url: id === 0 ? `${context.API}/scenarios/` : `${context.API}/scenarios/${id}/`,
            data: {...values, 'scenario_type': stype},
            headers: {...context.data.headers}
        }).then(
            res => {
                message.success(id === 0 ? 'New scenario created' : 'Scenario updated');
                context.update();

                if (goToTask) {
                    // if applying add (another) task
                    history.push(`/task/scenario=${res.data.id}-task=0`)
                    return;
                }

                history.push(stype ? '/exposure' : '/ihcs')
            }
        ).catch(() => message.error("ERROR OCCURED"))
    }



    // delete task by its id
    const handleTaskDelete = id => {
        axios.delete(
                `${context.API}/tasks/${id}/`,
                {headers: {...context.data.headers}}
            ).then(
                () => {
                    message.warning("Task deleted");
                    context.update();
                }
            ).catch(
                () => message.error("ERROR OCCURED")
            )
    }






    // custom buttons in Navigation component
    const CustomNavigation = (
        <div>
            <Link to={stype === 0 ? "/ihcs" : "/exposure"}>
                <ThemedButton
                    label={<span><CloseOutlined /> Close</span>}
                    type="link-outlined"
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />

            <Link to={`/report/es=${id}`}>
                <ThemedButton
                    label={<span><PrinterOutlined /> Report</span>}
                    type="link-outlined"
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />
        
            <ThemedButton 
                type="primary"
                label={<span><SaveOutlined /> Save</span>} 
                onClick={form.submit}
                buttonType="submit"
            />
        </div>
    )    

    

    // first part is form for scenario
    // later are forms for tasks
    return(
        <div>
            <Navigation 
                title="New scenario"
                subTitle="Scenario identification and substance"
                customNavigation={CustomNavigation}
            />

            <main>
                <Divider className={context.data.company}>
                    Scenario Identification
                </Divider>
                <Form 
                    {...context.data.scaling}
                    name="new-scenario"
                    initialValues={
                        id === 0 
                        ? {name:'', substance: '0', info: ''}
                        : {...context.data.scenarios.find(o => o.id === id)}
                    }
                    form={form}
                    onFinish={handleSubmit}
                >

                    <Form.Item
                        label="Scenario name"
                        name="name"
                        required
                        rules={[
                            {required: true, message: "Name is required"},
                            {min: 3, message: "Name is too short"},
                            {max: 50, message: "Name is too long"}
                        ]}
                    >
                        <input minLength={3} maxLength={50} autoComplete="off" />
                    </Form.Item>

                    
                    <Form.Item
                        label="Substance"
                        name="substance"
                        required
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('substance') !== '0') {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('Substance is required'));
                                },
                            })
                        ]}
                    >
                        
                        <select>
                            <option value='0'></option>
                            { context.data.substances.map(
                                substance => (
                                    <option key={substance.id} value={substance.id}>
                                        {substance.name}
                                    </option>
                                )
                            )}
                        </select>
                            
                    </Form.Item>

                    <Form.Item label="Additional information" name="info">
                        <textarea rows={5} />
                    </Form.Item>

                    {
                        role.user === scenario.user | id === 0 ?
                            <Form.Item
                                name="private"
                                label="Private*"
                                normalize={() => !scenario.private}
                            >
                                <input type="checkbox" defaultChecked={scenario.private} />
                            </Form.Item>
                        : <div />
                    }
                    
                </Form>

                <p>* Private scenario means that it is only visible to your. 
                        Making it non-private, it becomes visible and editable by the users who are part of the same site.
                    </p>

                <div>
                    { 
                        stype === 1
                        ? 
                        <Row>
                            <Col 
                                {...context.data.tailedScaling.wrapperCol}
                                style={{ textAlign: "center" }}
                            >
                                <Link to={`/exposure-estimates/es=${id}`}>
                                    <ThemedButton
                                        type='success'
                                        label={<span><CalculatorOutlined /> Model Exposure</span>}
                                    />
                                </Link>
                            </Col>
                        </Row>
                        : <div />
                    }
                </div>

                <Divider className={context.data.company}>
                    Activity Tasks
                </Divider>

                <Row>
                    <Col
                        md={{ offset: 6, span: 12 }}
                        xs={{ span: 24 }}
                    >
                        <div>
                            {
                                duration > 480
                                ? <Alert 
                                    style={{ marginBottom: 25 }}
                                    type="error"
                                    showIcon
                                    icon={<ClockCircleOutlined />}
                                    message="Duration error"
                                    description="Total duration must be 480min or less" 
                                />
                                : ''
                            }
                        </div>
                    </Col>
                </Row>
                <div>
                    {
                        tasks.length === 0
                        ? <Empty 
                            description={
                                <span>
                                    No tasks added yet to this scenario
                                    <div><ThemedButton
                                        type="primary"
                                        label="Add task"
                                        onClick={ 
                                            () => {
                                                form.validateFields()
                                                .then(() => handleSubmit(form.getFieldsValue(), true))
                                                .catch(e => console.log(e)) 
                                            }
                                        }
                                    /></div><br/>
                                    <div style={{ fontSize: 12, opacity: 0.8 }}>
                                        Clicking on 'add task' will automatically save the current scenario
                                    </div>
                                </span>} 
                        />  
                        : <div />
                    }
                </div>

                <Row>
                {tasks.map(
                    task => (
                        <Col 
                            key={task.name}
                            md={{ offset: 6, span: 12 }}
                            xs={{ span: 24 }}
                            style={{
                                marginBottom: 25,
                                border: '1px solid rgb(230, 230, 230)',
                                borderRadius: 5,
                                padding: 5
                            }}
                        >
                            <div>
                                <Row 
                                    justify='space-between'
                                    style={{ 
                                        borderBottom: '1px solid rgba(77,77,77,0.1)',
                                        marginBottom: 20,
                                        paddingBottom: 5
                                    }}
                                >
                                    <Col><strong>{task.name}</strong></Col>
                                    <Col><div><ClockCircleOutlined /> {task.duration}min</div></Col>
                                </Row>

                                <Row justify='space-between'>
                                    <Col>
                                        <Popconfirm
                                            title="Are you sure?"
                                            okText="Yes"
                                            onConfirm={() => handleTaskDelete(task.id)}
                                        >
                                            <ThemedButton 
                                                type="link"
                                                label={<span><DeleteOutlined /> Delete</span>}
                                            />
                                        </Popconfirm>
                                    </Col>
                                    <Col>
                                        <Link to={`/task/scenario=${task.scenario}-task=${task.id}`}>
                                            <ThemedButton
                                                type="link"
                                                label={<span><EditOutlined/> Edit</span>}
                                            />
                                        </Link>
                                    </Col>
                                    {
                                        stype === 0
                                        ? <Col span={7}>
                                            {
                                                task.lc === "" ?
                                                <span style={{ fontSize: 10 }}>No predicted LC</span>
                                                : <span style={{ fontSize: 10 }}>{labels[task.lc]}</span>
                                            }
                                        </Col>
                                        : ''
                                    }
                                    {
                                        stype === 0
                                        ? <Col>
                                            <Link to={`/controls/es=${id}-task=${task.id}`}>
                                                <ThemedButton
                                                    type='link'
                                                    label={<div><span style={task.lc === "" ? { color: "red" } : { color: "green" }}><ControlOutlined  style={{ color: "green" }}/> Control Strategies</span></div>}
                                                />
                                            </Link>
                                        </Col>
                                        : ''
                                    }
                                </Row>
                            </div>
                        </Col>)
                    )
                }</Row>

                
                <div>{
                    tasks.length < 4 && tasks.length > 0
                    ? <Row>
                        <Col {...context.data.tailedScaling.wrapperCol}
                            style={{ textAlign: "center" }}
                        >
                            <Link to={`/task/scenario=${id}-task=0`}>
                                <ThemedButton type="primary" label="Add Another Task" />
                            </Link>
                        </Col>
                    </Row>
                    : <div />
                }</div>

                <br />
                
                {
                    stype === 1
                    ? <div>
                        <Divider className={context.data.company}>
                            Measured exposure
                        </Divider>
                        <Row>
                            <Col
                                md={{ offset: 6, span: 12 }}
                                xs={{ span: 24 }}
                            >
                                <div>
                                    <p>
                                        The measurements are used to update
                                        modelled (i.e. prior) exposure estimates
                                        through the Bayesian inference. Both the
                                        prior and the posterior estimates are displayed
                                        after clicking on the button "Model Exposure".
                                    </p><br/>
                                    <div>{
                                        measured.length === 0
                                        ? <Empty description="No measurements added" />
                                        : <div>
                                            {
                                                measured.map(
                                                    (item, inx) => (
                                                        <Row key={inx}>
                                                            <Col md={{span:4}} xs={{span:8}}>
                                                                {`Measured ${inx+1}`}:
                                                            </Col>
                                                            <Col span="8">
                                                                <span>{item} &mu;g/m<sup>3</sup></span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                )
                                            }
                                        </div>
                                    }</div>
                                    <br/>

                                    <div>{
                                        tasks.length === 0
                                        ? <Alert
                                            type="warning"
                                            message="You cannot add measurements for a scenario with no activity tasks defined"
                                            showIcon
                                        />
                                        : <div style={{ textAlign: "center" }}>
                                            <ThemedButton
                                                type="primary"
                                                label={<span>
                                                    <CloudUploadOutlined /> Upload measurements 
                                                    </span>}
                                                onClick={() => setModal({ visible: true })}
                                            />
                                        </div>
                                    }</div>
                                </div>
                            </Col>
                        </Row>
                    </div> 
                    : <div />
                }
            </main>
            <br/><br/>

            <MeasuredScenario modal={ modal } onClose={() => setModal({})}/>
        </div>
    )
}
export default Scenario;