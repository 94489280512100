import { CheckCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Popconfirm } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import CS5 from './CS5';
import Measured from './Measured';
import ThemedButton from './ThemedButton';




/*
    list all controls
    both recommended and non-recommended 
    in a table view (coloring in green or red)
*/
function ControlsViewTable(props) {

    // states
    const [ modal, setModal ] = useState({ visible: false })
    const [ cs5, setCS5 ] = useState(false);

    // constants
    const controls = require('../json/controls.json');
    const labels = require('../json/labels.json');
    const exposure = props.exposure;
    const oel = props.oel;
    const score = props.score;


    // TASK - UPDATE 13 MAY FOR BAYES
    const context = useContext(AppContext);
    let { task } = useParams();
    task = {...context.data.tasks.find(o => o.id === parseInt(task))};
    let measured = JSON.parse(task.measured);
    let posterior = JSON.parse(task.posterior);
    

    // whatever the arrays include -> colored in green
    // not included -> remains red
    // green and red defined as classNames (in app.css)
    let control = [];
    let cs = [];
    let sh = [];
    let lc = [];
    let spec = [];

    for (let i in controls) {
        let lcFactor = (
            posterior[controls[i].key] 
            ? posterior[controls[i].key]['p95'] * 1000 / exposure
            : 1
        );
        let risk = lcFactor * controls[i][score] * exposure / oel >= 1;
        controls[i].risk = risk;

        if (!risk) {
            // if no risk then color parent controls in green
            // otherwise they remain red
            control.push(controls[i].key);
            cs.push(`cs${controls[i].cs}`);
            sh.push(controls[i].sh);
            lc.push(controls[i].lc);
            spec.push(controls[i].cpt);
        }
    }



    // local control cells
    // include labels and upload measured data button
    // also status icon if uploaded
    const ControlCell = key => (
        <Row justify='space-between'>
            <Col span='20' style={{ textAlign:'left' }}>{labels[key]}</Col>
            <Col>
                <div>
                {
                    control.includes(key)
                    ? <div />
                    : <Tooltip
                        title="Upload exposure measurements"
                    >
                        <Popconfirm
                            onConfirm={() => setModal({ ...modal, visible: true, lc: key })}
                            title={
                                <p style={{ width: 500 }}>
                                    <strong>Important:</strong> IH exposure data must have been
                                    collected for a similar exposure scenario
                                    where the same local control option was applied.
                                    Data must have been collected via a validated
                                    method.  Review with IH or EHS professional
                                    to confirm applicability of the data before uploading.
                                </p>
                            }
                        >
                            {
                                key in measured
                                ? <CheckCircleOutlined style={{ fontSize: 18, marginRight: 3 }} />
                                : ''
                            }
                            <CloudUploadOutlined style={{ fontSize: 18 }} />
                        </Popconfirm>
                    </Tooltip>
                }
                </div>
            </Col>
        </Row>
    )


    

    // display cs-5
    if (cs5) {
        return <CS5 exposure={exposure} oel={oel} />
    }



    // Create LC button
    const LCinTD = (lc, tdProps) => (
        <td {...tdProps} className={!control.includes(lc) ? 'red-td' : 'green-td'}>
            {control.includes(lc)
            ? <ThemedButton
                className={ props.lc === lc ? 'control-selected' : '' }
                type={ 'success-outline' }
                disabled={ !control.includes(lc) }
                label={ ControlCell(lc) }
                style={{
                    width: '100%',
                    textAlign: 'left',
                    minHeight: 40,
                    marginBottom: 2
                }}
                onClick={() => props.onControlChange(lc)}
            />
            : ControlCell(lc)}
        </td>
    )


    return(
        <div>
            <Measured modal={ modal } onClose={() => setModal({})}/>

            <table
                className="table-border"
                style={{ borderCollapse: 'separate' }}
            >
                <thead>
                    <tr>
                        <th rowSpan="2">Control strategy</th>
                        <th rowSpan="2">State of handling</th>
                        <th colSpan="2">Local control concept</th>
                        <th rowSpan="2">Connections / processing / transfer points</th>
                    </tr>
                    <tr>
                        <th>Category</th>
                        <th>Type</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td className={cs.includes('cs1') ? 'green-td' : 'red-td'}>
                            {labels['cs1']}
                        </td>
                        <td 
                            rowSpan="8"
                            className={sh.includes('open') ? 'green-td' : 'red-td'}
                        >
                            {labels['open']}
                        </td>
                        {LCinTD('gv', {colSpan: 2})}
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td rowSpan="7" 
                            className={cs.includes('cs2') ? 'green-td' : 'red-td'}
                        >
                            {labels['cs2']}
                        </td>
                        <td
                            rowSpan="7"
                            className={lc.includes('lev') ? 'green-td' : 'red-td'}
                        >
                            {labels['lev']}
                        </td>
                        {LCinTD('lev1')}
                        <td 
                            rowSpan="7"
                            className={spec.includes('low') ? 'green-td' : 'red-td'}
                        >{labels['low']}</td>
                    </tr>
                    {['lev2', 'lev3', 'lev4', 'lev5', 'lev6', 'lev7'].map(
                        item => <tr key={item}>{LCinTD(item)}</tr>
                    )}
                    <tr>
                        <td
                            rowSpan="4"
                            className={cs.includes('cs3') ? 'green-td' : 'red-td'}
                        >{labels['cs3']}</td>
                        <td
                            rowSpan="4"
                            className={sh.includes('enclosed') ? 'green-td' : 'red-td'}
                        >{labels['enclosed']}
                        </td>
                        <td 
                            rowSpan="2" 
                            className={lc.includes('ve') ? 'green-td' : 'red-td'}
                        >{labels['ve']}</td>
                        {LCinTD('ve1')}
                        <td rowSpan="7"
                            className={spec.includes('medium') ? 'green-td' : 'red-td'}
                        >{labels['medium']}</td>
                    </tr>
                    <tr>{LCinTD('ve2')}</tr>
                    <tr>
                        <td
                            rowSpan="2"
                            className={lc.includes('sc') ? 'green-td' : 'red-td'}
                        >
                            {labels['sc']}
                        </td>
                        {LCinTD('sc1')}
                    </tr>
                    <tr>{LCinTD('sc2')}</tr>
                    <tr>
                        <td
                            rowSpan="5"
                            className={cs.includes('cs4') ? 'green-td' : 'red-td'}
                        >{labels['cs4']}
                        </td>
                        <td rowSpan="6"
                            className={sh.includes('contained') ? 'green-td' : 'red-td'}
                        >{labels['contained']}
                        </td>
                        <td 
                            rowSpan="5"
                            className={lc.includes('sbc') ? 'green-td' : 'red-td'}
                        >{labels['sbc']}</td>
                       {LCinTD('sbc1')}
                    </tr>
                    {['sbc2', 'sbc3'].map(
                        item => <tr key={item}>{LCinTD(item)}</tr>
                    )}
                    <tr>
                        {LCinTD('sbc4')}
                        <td rowSpan="3"
                            className={spec.includes('high') ? 'green-td' : 'red-td'}
                        >{labels['high']}</td>
                    </tr>
                    <tr>{LCinTD('sbc5')}</tr>
                    <tr>
                        <td
                            className={cs.includes('cs5') ? 'green-td' : 'red-td'}
                        >{labels['cs5']}
                        </td>
                        <td
                            className={lc.includes('dbc') ? 'green-td' : 'red-td'}
                        >{labels['dbc']}</td>
                        <td
                            className={cs.includes('cs5') ? 'green-td' : 'red-td'}
                        >
                            <ThemedButton
                                type="link-outlined"
                                label="Select CS-5"
                                onClick={() => setCS5(true)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default ControlsViewTable;