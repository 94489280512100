import React, { useContext } from 'react';
import { AppContext } from '../AppContext';



// themed button for dark, light; ferring and takeda
function ThemedButton(props) {
    const context = useContext(AppContext);
    return(
        <button 
            style={props.style}
            type={props.buttonType ? props.buttonType : "button"}
            className={`${props.type}-button ${context.data.theme}-button ${context.data.company}-button ${props.className}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.label}
        </button>
    )
}
export default ThemedButton;