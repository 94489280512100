import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import ThemedButton from './ThemedButton';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../AppContext';
import Spinner from './Spinner';



/*
    Upload measured exposure values corresponding
    to a given task and one exposure control type

    Only for IHCS pathway
*/
function Measured(props) {

    const [ number, setNumber ] = useState(3);
    const [ loading, setLoading ] = useState(false);
    const context = useContext(AppContext);
    const labels = require('../json/labels.json');
    let { task } = useParams();
    const taskID = parseInt(task);
    task = {...context.data.tasks.find(o => o.id === taskID)};
    task.measured = JSON.parse(task.measured);
    const [ form ] = Form.useForm();
    const lc = props.modal.lc;
    let initialValues = {};

    // Set initial values        
    for (let i = 0; i < number; i++) {
        initialValues[`m${i}`] = '';
    }

    useEffect(() => {    
        // if already saved for this lc
        const measured = task.measured;
        if (measured[lc]) { 
            let measuredLC = measured[lc];

            for (let i in measuredLC) {
                initialValues[`m${i}`] = measuredLC[i];
            }
            setNumber(measuredLC.length);
        }
        form.setFieldsValue(initialValues);
    }, [props])


    if (loading) {
        return <div style={{ 
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            margin: 0,
            textAlign: "center",
            background: "rgba(38,38,38,0.8)",
            color: "white"
        }}><Spinner style={{ marginTop: "50%"}} customLabel="Running Bayesian"/>
        </div>
    }

    return(
        <Modal
            visible={ props.modal.visible }
            title='Upload measurements'
            onCancel={ props.onClose }
            footer={(
                <div>
                    <ThemedButton
                        type="link-outlined"
                        label="Cancel"
                        onClick={props.onClose}
                    />
                    <ThemedButton
                        type="primary"
                        label="Save"
                        onClick={ form.submit }
                    />
                </div>
            )}
        >
            {
                props.scenario
                ? <div></div>
                :<div><strong>{ labels[props.modal.lc] }</strong></div>
            }
            <br/>
            <Form
                name='measurements'
                form={ form }
                labelCol={{ md: {span:6} }}
                wrapperCol={{ md: {span: 18 }}}
                labelAlign='left'
                initialValues={initialValues}
                onFinish={
                    values => {
                        setLoading(true);
                        let measuredList = []
                        for (let key in values) {
                            if (values[key]) {
                                measuredList.push(parseFloat(values[key]));
                            }
                        }
                        let data = { 
                            lc: lc,
                            measured: measuredList
                        };
              
                        axios.post(
                            `${context.API}/bayes/${taskID}/`,
                            data,
                            {headers: {...context.data.headers}}
                        ).then(
                            res => {
                               if (res.status === 200) {
                                    localStorage.setItem("show-table", true);
                                    context.update();
                                    message.success("Measurements added");
                               } else {
                                   message.warning("Server too busy, please try later");
                               }
                               setLoading(false);
                            })
                        .catch( () => {
                            message.error("ERROR OCCURED");
                            setLoading(true);
                        })
                    }
                }
            >
            {
                [...Array(number)].map(
                    (_, inx) => (
                        <Form.Item label={`Value ${inx+1}`} key={inx} required={inx<2}>
                            <Input.Group compact>
                                <Form.Item
                                    name={`m${inx}`}
                                    noStyle
                                    rules={
                                        inx<2
                                        ? [{ 
                                                required: true,
                                                message: 'At least two measurements required' 
                                            }] 
                                        : []
                                    }
                                >
                                    <input type="number" min={0} step={0.0001}/>
                                </Form.Item>
                                <Form.Item noStyle>
                                    <div style={{ marginLeft: 10 }}>&mu;g/m3</div>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    )
                )
            }

                <Form.Item wrapperCol={{ md: {offset: 6 }}}>
                    <ThemedButton
                        type='primary'
                        label="Add another"
                        onClick={() => setNumber(number+1)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default Measured;