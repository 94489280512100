import { SaveOutlined } from '@ant-design/icons';
import { Alert, Divider, Form, Input, message, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Navigation from './Navigation'
import Spinner from './Spinner';
import ThemedButton from './ThemedButton';


// Upgrade March 2022
// New site 
// Authorised: Manager
export default function Site() {
    const [state, setState] = useState({});
    const context = useContext(AppContext);
    const api = context.API;
    const headers = context.data.headers;
    const [form] = Form.useForm();
    let history = useHistory();

    // site id from requested url
    let { id } = useParams();
    id = parseInt(id);

    // Fetch regular users
    // Only regular users can become Site managers
    useEffect(() => {
        axios.get(`${api}/get-regular-users/`, {headers})
        .then(res => {
            if (res.status === 200) {

                if (id > 0) {
                    // if id<>0: get saved data
                    // allow edit
                    const promises = [
                        axios.get(`${api}/sites/${id}/`, {headers}),
                        axios.get(`${api}/get-site-managers/`, {headers})
                    ]
                    Promise.all(promises)
                    .then(res1 => {
                        if (res1[0].status === 200 && res1[1].status === 200) {
                            setState({
                                regularUsers: res.data,
                                loaded: true,
                                site: res1[0].data,
                                managers: res1[1].data
                            })
                        } else {setState({loaded: true, error: true})}
                    })
                    .catch(
                        e => {
                            console.log(e);
                            setState({loaded: true, error: true});
                        }
                    )
                } else {
                    setState({
                        loaded: true,
                        regularUsers: res.data
                    })
                }
            } else {setState({loaded: true, error: true})}
        })
        .catch(
            e => {
                console.log(e);
                setState({loaded: true, error: true});
            }
        )
    }, [])

    const CustomNavigation = (
        <div>
            <Link to="/site-management">
                <ThemedButton type="link-outlined" label="Cancel" />
            </Link>
            <Divider type="vertical"/>
            <ThemedButton
                type="primary"
                label={<span><SaveOutlined/> Save</span>}
                onClick={form.submit}
            />
        </div>
    )


    // while loading regular users
    if (!state.loaded) {
        return <div> <Spinner /> </div>
    }

    // if error while fetching
    // If error: display error alert
    if (state.error) {
        return(
            <div>
                <Link to="/">
                    <ThemedButton
                        label="<< back home"
                        type="link"
                        style={{ color: "blue" }}
                    />
                </Link>
                <br/><br/>
                <div>
                    <Alert
                        type="warning"
                        message="Failed to download"
                        description={<span>
                            Server did not respond as expected. Please try again or, if this continue,
                                <Link to='/contact'>
                                    <ThemedButton
                                        label="Contact support"
                                        type="link"
                                        style={{ color: 'blue', textDecoration: 'underline' }}
                                    />
                                </Link>
                            </span>}
                        showIcon
                    />
                </div>
            </div>
        )
    }

    // if id > 0 then we need to set manager's email
    let manager = ''
    if (id > 0 && state.managers.length > 0) {
        manager = state.managers.find(o => o.id === state.site.manager).email;
    }

    return (
        <div>
            <Navigation
                title="New Site"
                subTitle="Site Data Form"
                customNavigation={CustomNavigation}
            />
            
            <Alert
                type="info"
                showIcon
                message="Create new site"
                description="Please specify name and location of the new site. The last field lists regular users (i.e. those that currently are neither managers or site managers."
            />
            <br/>

            <Form
                form={form}
                name="siteForm"
                {...context.data.scaling}
                initialValues={{...state.site, manager: manager }}
                onFinish={
                    values => {
                        if (id === 0) {
                            // post request
                            axios.post(`${api}/sites/`, {...values}, {headers: {...headers} })
                            .then(res => {
                                if (res.status === 208) {
                                    message.warning("Another site uses this name. Enter another name");
                                    return;
                                }
                                message.success("New site created!");
                                history.push('/site-management');
                            })
                            .catch(() => message.error("An error occurred!"))
                            return;
                        } else {
                            // put request (update)
                            axios.put(`${api}/sites/${id}/`, {...values, id: id}, {headers: {...headers} })
                            .then(res => {
                                if (res.status === 208) {
                                    message.warning("Another site uses this name. Enter another name");
                                    return;
                                }
                                message.success("Site updated!");
                                history.push('/site-management');
                            })
                            .catch(() => message.error("An error occurred!"))
                            return;
                        }
                    }
                }
            >
                <Form.Item
                    required
                    label="Site name"
                    name="name"
                    rules={[{ required:true, message: "Name is needed"},{min:2, message: "Too short"}]}
                >
                    <Input maxLength={25} />
                </Form.Item>

                <Form.Item
                    required
                    name="location"
                    label="Site location"
                    rules={[{ required:true, message: "Site location is needed"},{min:5, message: "Too short"}]}
                >
                    <Input maxLength={25} />
                </Form.Item>

                <Form.Item
                    require
                    name="manager"
                    label="Site Manager"
                    rules={[{ required:true, message: "Site manager's email is needed"}]}
                >
                    <Select
                        showSearch
                        placeholder="Search by email"
                        optionFilterProp='children'
                    >
                    {
                        state.regularUsers.map(
                            user => (
                                <Select.Option key={user.email} value={user.email}>{user.email}</Select.Option>
                            )
                        )
                    }
                    </Select>
                </Form.Item>
            </Form>
        </div>
    )
}