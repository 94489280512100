import { CalculatorOutlined, ControlOutlined, ExperimentOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Col, Row, Statistic } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../AppContext";
import Spinner from './Spinner';


// Statistics for home page
function Statistics() {
    const [ state, setState ] = useState({});
    const context = useContext(AppContext);

    useEffect(() => {
        axios.get(
            `${context.API}/counters/`,
            { headers: context.data.headers }
        ).then(
            res => {
                console.log(res.data);
                setState({
                    data: res.data,
                    loaded: true
                })
            }
        ).catch(
            e => console.log(e)
        )
    }, [])



    if (!state.loaded) {
        return <Spinner />
    }


    const styling = {
        col: {
            textAlign: "center"
        },
        div: {
            padding: 15,
            border: "1px solid rgba(220,220,220,0.4)",
            width: 200,
            height: 200,
            borderRadius: 5,
            paddingTop: 50
        }
    }

    return(
        <div style={{ marginBottom: 10 }}>
            <p>
                The numbers below show the
                total number of the registered users,
                saved substances and the scenarios
                established for IH Control Strategies 
                and Exposure Assessment. 
            </p><br/>

            <Row justify="space-between" gutter={16}>
                <Col style={styling.col}>
                    <div style={styling.div}><Statistic 
                        title="Users"
                        value={state.data.users}
                        prefix={<UserOutlined />} 
                    /></div>
                </Col>

                <Col style={styling.col}>
                    <div style={styling.div}><Statistic 
                        title="Substances"
                        value={state.data.substances}
                        prefix={<ExperimentOutlined />} 
                    /></div>
                </Col>

                <Col style={styling.col}>
                    <div style={styling.div}>
                        <div>
                            <Statistic 
                                title="IHCS* Scenarios"
                                value={state.data.ih_scenarios}
                                prefix={<CalculatorOutlined />} 
                            />
                        </div><br/>
                        <div>
                            *Industrial Hygiene Control Strategies
                        </div>
                    </div>
                </Col>

                <Col style={styling.col}>
                    <div style={styling.div}>
                        <div>
                            <Statistic 
                                title="EA* Scenarios"
                                value={state.data.scenarios}
                                prefix={<CalculatorOutlined />} 
                            />
                        </div><br/>
                        <div>
                            *Exposure Assessment
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default Statistics;