import React, { useContext } from 'react';
import { AppContext } from '../AppContext';



// Modal showing results in table

// props
//  * scenario ID
//  * task ID
export default function TableExposureResults(props) {

    if (!props.scenario && !props.task) return <div />;

    const context = useContext(AppContext);
    const rounding = context.functions.rounding;
    let tasks = [];
    let exposure = {};

    // depending on props (TWA or task based exposure)
    if (props.twa) {
        tasks = context.data.tasks.filter(o => o.scenario === props.scenario);
        tasks = tasks.map(o => Object.assign({}, o));
        
        // create TWA (calculation based on task durations)
        let twa = JSON.parse(tasks[0].exposure);
        for (let key in twa) { twa[key] = 0 }

        for (let i=0; i<tasks.length; i++) {
            let exposure = JSON.parse(tasks[i].exposure);

            for (let key in exposure) {
                twa[key] = twa[key] + exposure[key] * tasks[i].duration / 480;
            }
        }
        exposure = twa;
    } else if(props.posterior) {
        let scenario = {...context.data.scenarios.find(o => o.id === props.scenario)};
        exposure = JSON.parse(scenario.posterior);
    } else {
        let task = context.data.tasks.find(o => o.id === props.task);
        exposure = JSON.parse(task.exposure);
    }

    
    // conversion to ug
    for (let key in exposure) {
        exposure[key] = exposure[key] * 1000;
    }


    const border = { borderBottom: '1px solid rgb(220,220,220)' }

    return(
        <table>
                <thead>
                    <tr style={border}>
                        <th rowSpan="2">Percentile</th>
                        <th rowSpan="2">Exposure, &mu;g/m<sup>3</sup></th>
                        <th colSpan="5" style={{ textAlign: 'center' }}>Confidence interval</th>
                    </tr>
                    <tr style={border}>
                        <th>70%</th>
                        <th>75%</th>
                        <th>80%</th>
                        <th>90%</th>
                        <th>95%</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>50<sup>th</sup></td>
                        <td>{rounding(exposure.p50)}</td>
                        <td>{rounding(exposure.p50_ci70_lower)}-{rounding(exposure.p50_ci70_upper)}</td>
                        <td>{rounding(exposure.p50_iq_lower)}-{rounding(exposure.p50_iq_upper)}</td>
                        <td>{rounding(exposure.p50_ci80_lower)}-{rounding(exposure.p50_ci80_upper)}</td>
                        <td>{rounding(exposure.p50_ci90_lower)}-{rounding(exposure.p50_ci90_upper)}</td>
                        <td>{rounding(exposure.p50_ci95_lower)}-{rounding(exposure.p50_ci95_upper)}</td>
                    </tr>
                    <tr>
                        <td>75<sup>th</sup></td>
                        <td>{rounding(exposure.p75)}</td>
                        <td>{rounding(exposure.p75_ci70_lower)}-{rounding(exposure.p75_ci70_upper)}</td>
                        <td>{rounding(exposure.p75_iq_lower)}-{rounding(exposure.p75_iq_upper)}</td>
                        <td>{rounding(exposure.p75_ci80_lower)}-{rounding(exposure.p75_ci80_upper)}</td>
                        <td>{rounding(exposure.p75_ci90_lower)}-{rounding(exposure.p75_ci90_upper)}</td>
                        <td>{rounding(exposure.p75_ci95_lower)}-{rounding(exposure.p75_ci95_upper)}</td>
                    </tr>
                    <tr>
                        <td>90<sup>th</sup></td>
                        <td>{rounding(exposure.p90)}</td>
                        <td>{rounding(exposure.p90_ci70_lower)}-{rounding(exposure.p90_ci70_upper)}</td>
                        <td>{rounding(exposure.p90_iq_lower)}-{rounding(exposure.p90_iq_upper)}</td>
                        <td>{rounding(exposure.p90_ci80_lower)}-{rounding(exposure.p90_ci80_upper)}</td>
                        <td>{rounding(exposure.p90_ci90_lower)}-{rounding(exposure.p90_ci90_upper)}</td>
                        <td>{rounding(exposure.p90_ci95_lower)}-{rounding(exposure.p90_ci95_upper)}</td>
                    </tr>
                    <tr>
                        <td>95<sup>th</sup></td>
                        <td>{rounding(exposure.p95)}</td>
                        <td>{rounding(exposure.p95_ci70_lower)}-{rounding(exposure.p95_ci70_upper)}</td>
                        <td>{rounding(exposure.p95_iq_lower)}-{rounding(exposure.p95_iq_upper)}</td>
                        <td>{rounding(exposure.p95_ci80_lower)}-{rounding(exposure.p95_ci80_upper)}</td>
                        <td>{rounding(exposure.p95_ci90_lower)}-{rounding(exposure.p95_ci90_upper)}</td>
                        <td>{rounding(exposure.p95_ci95_lower)}-{rounding(exposure.p95_ci95_upper)}</td>
                    </tr>
                    <tr style={border}>
                        <td>99<sup>th</sup></td>
                        <td>{rounding(exposure.p99)}</td>
                        <td>{rounding(exposure.p99_ci70_lower)}-{rounding(exposure.p99_ci70_upper)}</td>
                        <td>{rounding(exposure.p99_iq_lower)}-{rounding(exposure.p99_iq_upper)}</td>
                        <td>{rounding(exposure.p99_ci80_lower)}-{rounding(exposure.p99_ci80_upper)}</td>
                        <td>{rounding(exposure.p99_ci90_lower)}-{rounding(exposure.p99_ci90_upper)}</td>
                        <td>{rounding(exposure.p99_ci95_lower)}-{rounding(exposure.p99_ci95_upper)}</td>
                    </tr>
                </tbody>
            </table>
    )
}