import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AppContext } from '../AppContext';
import { Divider, Empty, message, Form, Rate, Tooltip } from 'antd';
import Spinner from './Spinner';
import Navigation from './Navigation';
import ThemedButton from './ThemedButton';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';



// bug reporting page
function ReportBug() {

    // states
    const [ update, setUpdate ] = useState(0);
    const [ bugs, setBugs ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ modal, setModal ] = useState(false);


    // context
    const context = useContext(AppContext);

    // form
    const [ form ] = Form.useForm();


    // colors for status
    const statusColor = {
        "Fixed" : "#52c41a",
        "Rejected": "#f5222d",
        "Unseen": "rgba(100,100,100, 0.4)",
        "Pending": "#faad14"
    }

    const statusOrder = {
        "Fixed" : 3,
        "Rejected": 2,
        "Unseen": 0,
        "Pending": 1
    }


    useEffect(() => {
        axios.get(
            `${context.API}/bugs/`,
            { headers: context.data.headers }
        ).then(
            res => {
                let bugs = res.data;
                bugs.sort((a,b) => statusOrder[a.status] > statusOrder[b.status] 
                    ? 1 : a.priority < b.priority ? 1 : -1 )
                setBugs(bugs);
                setLoading(false);
            }
        ).catch(
            () => {
                setLoading(false);
                message.error("ERROR OCCURED");
            }
        )
    }, [update])


    
    // while loading spin
    if (loading) return <Spinner />



    return(
        <div>
            <Navigation 
                title="Bugs"
                subTitle="Bugs and update requests"
                customNavigation={
                    <div>
                        <Link to="/">
                            <ThemedButton
                                type="link-outlined"
                                label={<span><CloseOutlined /> Close</span>}
                            />
                        </Link>
                        <Divider className={context.data.company} type="vertical" />
                        <ThemedButton
                            type="primary"
                            label={<span>Report new</span>}
                            onClick={() => setModal(true)}
                        />
                    </div>
                } 
            />


            <Modal
                title="New bug"
                visible={modal}
                onCancel={() => setModal(false)}
                footer={
                    <div>
                        <ThemedButton
                            type="link-outlined"
                            label={<span>Cancel</span>}
                            onClick={() => setModal(false)}
                        />
                        <ThemedButton
                            type="primary"
                            label="Save"
                            onClick={form.submit}
                        />
                    </div>
                }
            >

                <Form
                    name="new-bug"
                    {...context.data.scaling}
                    form={form}
                    initialValues={{
                        title: '',
                        text: '',
                        priority: 1
                    }}
                    onFinish={
                        values => {
                            axios({
                                method: 'post',
                                url: `${context.API}/bugs/`,
                                data: values,
                                headers: context.data.headers
                            }).then(
                                () => {
                                    setUpdate(update+1);
                                    message.success("Bug reported");
                                    setModal(false);
                                }
                            ).catch(
                                () => message.error("ERROR OCCURED")
                            )
                        }
                    }
                >
                    <Form.Item
                        required
                        name="title"
                        label="Title"
                        rules={[
                            { required: true, message: "Title is required"},
                            { max: 50, message: "Too long"}
                        ]}
                    >
                        <input type="text" maxLength={50} />
                    </Form.Item>

                    <Form.Item
                        required
                        name="text"
                        label="Description"
                        rules={[{ required: true, message: "Text is required"}]}
                    >
                        <textarea rows="5" />
                    </Form.Item>

                    <Form.Item
                        required
                        name="priority"
                        label="Priority"
                    >
                        <Rate />
                    </Form.Item>
                </Form>
            </Modal>

            <main>
                <div style={{ fontSize: 22 }}>Reported bugs and update requests</div>
                <br/>
                <br/>

                {
                    bugs.length === 0
                    ? <Empty />
                    : <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Reported by</th>
                            <th>Priority</th>
                            <th>Status</th>
                        </tr>
                    </thead>

                    <tbody>{
                        bugs.map(
                            bug => (
                                <tr key={bug.id}>
                                    <td>{bug.id}</td>
                                    <td><strong>{bug.title}</strong></td>
                                    <td>
                                        <Tooltip
                                            title={bug.text}
                                        >
                                            {bug.text.substr(0, 50)}...
                                        </Tooltip>
                                    </td>
                                    <td>{bug.email}</td>
                                    <td
                                        style={{
                                            color: `rgb(${50*bug.priority}, ${250/bug.priority}, 170)`,
                                            fontWeight: 700
                                        }}
                                    >{bug.priority}</td>
                                    <td
                                        style={{
                                            color: statusColor[bug.status],
                                            fontWeight: 700
                                        }}
                                    >{bug.status}</td>
                                </tr>
                            )
                        )
                    }</tbody>
                </table>
                }
            </main>
        </div>
    )
}
export default ReportBug;