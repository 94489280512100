import React from "react";
import ReactDOM from "react-dom";
import 'antd/dist/antd.css';
import App from './App';




if (process.env.NODE_ENV !== 'production') {
   console.log('Looks like we are in development mode!');
}

ReactDOM.render(<App />, document.getElementById('root'));
