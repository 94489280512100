import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import ManagerPage from './ManagerPage';
import Navigation from './Navigation';
import RegularUserPage from './RegularUserPage';
import SiteManagerPage from './SiteManagerPage';




// Upgrade (user roles) March-2022
// This is wrapper component
// It is root component for manager, site manager and regular user
export default function SiteManagement() {
    const context = useContext(AppContext);
    const role = context.data.role;

    // depending on user role
    // different component is rendered
    const rolePage = {
        'manager': <ManagerPage />,
        'site': <SiteManagerPage />,
        'regular': <RegularUserPage />
    }

    return (
        <div>
            <Navigation title="IH tool" 
                subTitle="Control strategies and exposure assessment" 
                settingsOnly
                homeLink
            />
            
            {rolePage[role]}
        </div>
    )
}