import { EnvironmentOutlined,UserAddOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Navigation from './Navigation';
import Statistics from './Statistics';
import ThemedButton from './ThemedButton';




const styling = {
    management: {
        width: '100%',
        border: "1px solid rgba(220,220,220, 0.4)",
        padding: 18,
        borderRadius: 5,
        marginBottom: 25,
        background: "rgba(210,210,210, 0.1)"
    },
    div: {
        width: 300,
        height: 320,
        border: "1px solid rgba(220,220,220, 0.4)",
        padding: 18,
        borderRadius: 5,
        marginBottom: 25,
        background: "rgba(210,210,210, 0.1)"
    },
    title: {
        height: 25,
        fontWeight: 700,
        borderBottom: "1px solid rgba(200,200,200, 0.2)",
        marginBottom: 20,
        paddingBottom: 5,
        fontSize: 18
    },
    content: {
        height: 200,
        fontSize: 14.5
    },
    footer: {
        textAlign: "center"
    },
    section: {
        fontSize: 16,
        fontWeight: 700,
        borderBottom: '2px solid rgba(200,200,200,0.2)'
    }
}

// roles
// upgrade March 2022 (user roles)
const roles = {
    'regular': 'Regular user',
    'site': 'Site manager',
    'manager': 'Manager'
}

const roleInfo = {
    'manager': 'Here you can manage (create/edit/delete) sites of your company. You receive requests from regular users who want to create a site and to be its "Site managers".',
    'site': 'Here you manage your site team. All requests received from regular non-site users who want to join your site are displayed here.',
    'regular': 'Here you can join a site. You can also request to become a site manager. A site is a group of users that work at the same site (e.g. Zurich Switzerland) that is part of your company. The site users can share substance or exposure data.'
}

const roleIcons = {
    'manager': <UsergroupAddOutlined />,
    'site': <UserAddOutlined />,
    'regular': <UserOutlined />
}

// Home page
function Start() {
    const context = useContext(AppContext);

    const mainElements = [
        {
            id: 1,
            label: "Substances",
            link: "/substances",
            dscr: "A database containing substances with their physical-chemical properties and toxicological values. The entered data is needed for providing recommendations regarding control strategies"
        }, {
            id: 2,
            label: "IH Control Strategies",
            link: "/ihcs",
            dscr: "Predicting Industrial Hygiene (IH) control strategies based on workplace exposure conditions and toxicological values defined for substances"
        }, {
            id: 3,
            label: "Exposure Assessment",
            link: "/exposure",
            dscr: "Modelling exposure values using Advanced REACH Tool (ART) as a higher tier approach recommended by the European Chemicals Agency (ECHA)"
        }
    ];





    return(
        <div>
            <Navigation title="IH tool" 
                subTitle="Control strategies and exposure assessment" 
                settingsOnly
            />

            <div
                style={{ 
                    marginBottom: 55,
                    fontSize: 22
                }}
            >{
                context.data.company === 'takeda'
                ?<div>
                    <strong>TAKTICS</strong> - Takeda Tool for Industrial Hygiene Control Strategies
                </div>
                :<div>
                    <strong>FERIHCS</strong> - Ferring Industrial Hygiene Contol Strategies
                </div>
            }</div>

            <main>
                
                <div style={styling.management}>
                    <Row justify='start' style={{ marginBottom: 15, borderBottom: '1px solid silver' }}>
                        <Col span="4">
                            <div style={{ fontSize:18, fontWeight: 'bold', textAlign: 'center' }}>
                                Site Management
                            </div> 
                        </Col>
                        <Col>
                            <div style={{ paddingLeft: 15, fontSize: 16 }}>
                                <span><strong>My role: </strong></span>
                                <span style={{ paddingLeft: 10 }}>{roleIcons[context.data.role]}</span>
                                <span style={{ paddingLeft: 10 }}><i>{roles[context.data.role]}</i></span> 
                            </div>
                        </Col>
                    </Row>
                    <Row justify='start' type="flex" align='middle'>
                        <Col span={4} style={{ textAlign: 'center', borderRight: '1px solid silver', verticalAlign: 'middle' }}>
                            <div>
                                <div style={{ marginBottom: 15 }}>
                                    <EnvironmentOutlined style={{ fontSize: 50 }} />
                                </div>
                                <div>
                                    <Link to='/site-management'>
                                        {
                                            context.data.roleRequests.length > 0 ?
                                            <Badge count={context.data.roleRequests.length}>
                                                <ThemedButton label="Manage" type="primary" />
                                            </Badge>
                                            : <ThemedButton label="Manage" type="primary" />
                                        }
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div style={{ paddingLeft: 15 }}>
                                <div>
                                    {roleInfo[context.data.role]}
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Link to="/user-roles-info">
                                    <ThemedButton
                                        type="link-outlined"
                                        label="Learn more"
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row justify="space-between">
                    {mainElements.map(item => (
                        <Col key={item.id}>
                            <div style={styling.div}>
                                <div style={styling.title}>
                                    {item.label}
                                </div>
                                <div style={styling.content}>
                                    {item.dscr}
                                </div>
                                <div style={styling.footer}>
                                    <Link to={item.link}>
                                        <ThemedButton type="primary" label={item.label} />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row><br/>

                {/* Graphs */}
                <div style={styling.section}>Summary of the software use:</div><br/>
                <Statistics />
                
            </main><br/><br/><br/><br/><br/>

            <footer style={{ 
                borderTop: '1px solid rgba(220,220,220,0.4)',
                paddingTop: 20
            }}>
                <Row justify="space-between">
                    <Col>
                        <div>
                            <p>Version 1.1</p>
                            April 2022<br/>
                            Developed by <strong>Nenad Savic</strong>
                            <br/>
                            <a href="mailto: nenadschem@gmail.com">Contact me</a>
                            <br/>
                            <a href="https://www.linkedin.com/in/nenad-savic-phd/" target="_blank">About me</a>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            Report a bug or suggest an update
                            <Link to='/report-a-bug'>
                                <ThemedButton 
                                    style={{ marginLeft : 20 }}
                                    type="link-outlined"
                                    label="Report" 
                                />
                            </Link>
                        </div>
                    </Col>
                </Row>
                
            </footer>
        </div>
    )
}
export default Start;