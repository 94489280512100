import { Col, Divider, Result, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Navigation from '../components/Navigation';
import ThemedButton from './ThemedButton';
import { CloseOutlined } from '@ant-design/icons';
import MyChart from './MyChart';
import Modal from 'antd/lib/modal/Modal';
import ExposureTable from './ExposureTable';


const scaling = {
    label: {
        md: { span: 10 }
    },
    wrapper: {
        md: { span: 12 }
    }
}


/*
    Exposure estimates of ART
    Percentiles, confidence intervals...table and graphics
    rendered on /exposure-estimates/es=x
*/
function Exposure() {
    
    const [ modal, setModal ] = useState({ visible: false });
    const context = useContext(AppContext);
    const labels = require('../json/labels.json');
    let { id } = useParams();
    id = parseInt(id);
    const scenario = context.data.scenarios.find( o => o.id === id );
    const tasksContext = context.data.tasks.filter(o => o.scenario === id);
    const rounding = context.functions.rounding;



    // PATCH 13.05.2021 Posterior Bayes
    let posterior = JSON.parse(scenario.posterior);
    let showPosterior = posterior['p50'] ? true : false;

    if (showPosterior) {
        // convert to ug/m3
        for (let key in posterior) {
            posterior[key] = rounding(posterior[key] * 1000);
        }
    }


    // if no tasks -> well show alert
    // PATCH 26.04.2021 to avoid break down
    if (tasksContext.length === 0) {
        return (
            <Result
                status="404"
                title="No tasks defined"
                subTitle="Sorry, no exposure can be calculated for scenarios with no tasks included"
                extra={
                    <Link to={`/scenario/stype=1-es=${id}`}>
                        <ThemedButton
                            type="primary"
                            label="Go back"
                        />
                    </Link>
                }
            />
        )
    }
  

    let tasks = tasksContext.map(o => ({...o}));
    const perc = context.data.percentile;
    const ci = context.data.ci;
    let substance = {};
    try {
        substance = {...context.data.substances.find( o => o.id === scenario.substance)};
    } catch(e) {
        console.log("not found")
    }
    

    // TWA exposure
    let twa = {central: 0, lower: 0, upper: 0};
    let tasksExposure = [];
    for (let i in tasks) {
        tasks[i].exposure = JSON.parse(tasks[i].exposure);
        let exposure = tasks[i].exposure;

        // conversion to ug/m3
        for (let key in exposure) {
            exposure[key] = exposure[key] * 1000;
        }

        let taskObj = {
            id: tasks[i].id,
            central: rounding(exposure[perc]),
            duration: tasks[i].duration,
            lower: rounding(parseFloat(exposure[`${perc}_${ci}_lower`])),
            upper: rounding(parseFloat(exposure[`${perc}_${ci}_upper`]))
        }
        tasksExposure.push(taskObj);
        twa.central += taskObj.central * taskObj.duration/480;
        twa.lower += taskObj.lower * taskObj.duration/480;
        twa.upper += taskObj.upper * taskObj.duration/480;
    }
    
    // rounding
    for (let key in twa) {
        twa[key] = rounding(twa[key]);
    }


    // width for modal
    // because values may include a lot of digits
    let width = 600;
    let factor = 1;
    if (modal.twa) {
        factor = twa.central.toString().length
    } else if (modal.task) {
        factor = tasks.find(o => o.id === modal.task).exposure.p99.toString().length;
    }
    
    width = width + factor * 50;

    if (width > 1200) { width = 1200 }

    return(
        <div>
            <Navigation
                subTitle="Modelled using ART"
                title="Exposure estimates"
                customNavigation={
                    <div>
                        <Link to={`/scenario/stype=1-es=${scenario.id}`}>
                            <ThemedButton 
                                type="link-outlined" 
                                label={<span><CloseOutlined /> Close</span>} 
                            />
                        </Link>
                    </div>
                }
            />

            <Modal
                visible={ modal.visible }
                title="All exposure results"
                footer={ 
                    <ThemedButton
                        type="link-outlined"
                        label="Close"
                        onClick={() => setModal({ visible: false})}
                    />
                }
                onCancel={ () => setModal({visible: false}) }
                width={ width }
            >
                <ExposureTable
                    twa={ modal.twa }
                    scenario={ modal.scenario }
                    task={ modal.task }
                    posterior={ modal.posterior }
                />
            </Modal>

            <main>
                <Row gutter={16}>
                    <Col md={{span: 12}}>
                        <Divider className={context.data.company} type="horizontal">
                            Substance
                        </Divider>
                        <Row>
                            <Col {...scaling.label}>Name: </Col>
                            <Col {...scaling.wrapper}>{substance.name}</Col>
                        </Row>
                        <Row>
                            <Col {...scaling.label}>OEL: </Col>
                            <Col {...scaling.wrapper}>
                                {substance.oel} {substance['oel_unit']}
                            </Col>
                        </Row>
                        <br />

                        <Divider className={context.data.company} type="horizontal">
                            Time-weighted average
                        </Divider>
                        <Row>
                            <Col {...scaling.label}>Exposure, {labels[perc]}: </Col>
                            <Col {...scaling.wrapper}>{twa.central} &mu;g/m<sup>3</sup></Col>
                        </Row>
                        <Row>
                            <Col {...scaling.label}>Confidence interval, {labels[ci]}: </Col>
                            <Col {...scaling.wrapper}>
                                <div>{twa.lower}-{twa.upper} &mu;g/m<sup>3</sup></div>
                                <br/>
                                <div>
                                    <ThemedButton
                                        type="link-outlined"
                                        label="Show more"
                                        onClick={() => setModal({ 
                                            visible: true,
                                            twa: true,
                                            scenario: scenario.id 
                                        })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {
                            showPosterior 
                            ? <div>
                                <br/><br/>
                                <p><strong>Posterior exposure:</strong></p>
                                <Row>
                                    <Col {...scaling.label}>Exposure, {labels[perc]}: </Col>
                                    <Col {...scaling.wrapper}>{posterior[perc]} &mu;g/m<sup>3</sup></Col>
                                </Row>
                                <Row>
                                    <Col {...scaling.label}>Confidence interval, {labels[ci]}: </Col>
                                    <Col {...scaling.wrapper}>
                                        <div>{posterior[`${perc}_${ci}_lower`]}-{posterior[`${perc}_${ci}_upper`]} &mu;g/m<sup>3</sup></div>
                                        <br/>
                                        <div>
                                            <ThemedButton
                                                type="link-outlined"
                                                label="Show more"
                                                onClick={() => setModal({ 
                                                    visible: true,
                                                    twa: false,
                                                    posterior: true,
                                                    scenario: scenario.id 
                                                })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            : <div />
                        }
                        <br/>
                        <Divider className={context.data.company} type="horizontal">
                            Task-based exposure (per task)
                        </Divider>
                        {
                            tasksExposure.map(
                                (item, inx) => (
                                    <div key={inx}>
                                        <p><strong>{`Task ${inx+1}: ${tasks[inx].name}`}</strong></p>
                                        <Row>
                                            <Col {...scaling.label}>Exposure, {labels[perc]}: </Col>
                                            <Col {...scaling.wrapper}>{item.central} &mu;g/m<sup>3</sup></Col>
                                        </Row>
                                        <Row>
                                            <Col {...scaling.label}>Confidence interval, {labels[ci]}: </Col>
                                            <Col {...scaling.wrapper}>
                                                <div>{item.lower}-{item.upper} &mu;g/m<sup>3</sup></div>
                                                <br/>
                                                <div>
                                                    <ThemedButton
                                                        type="link-outlined"
                                                        label="Show more"
                                                        onClick={() => setModal({ visible: true, task: item.id })}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </div>
                                )
                            )
                        }
                    </Col>
                    <Col md={{span: 12}}>
                        <MyChart
                            oel={substance.oel}
                            oelUnit={substance['oel_unit']}
                            twa={twa.central} 
                            tasks={[...tasksExposure.map(o => o.central)]}
                        />
                    </Col>
                </Row>
                
            </main>


        </div>
    )
}
export default Exposure;

