import { ClockCircleOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Col, Divider, message, Row, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Navigation from './Navigation';
import ThemedButton from './ThemedButton';
import { Form } from 'antd';
import ControlsViewDefault from './ControlsViewDefault';
import ControlsViewTable from './ControlsViewTable';
import axios from 'axios';
import Spinner from './Spinner';

/*
    output after running IH control strategies scenario
    results showing which control strategies are acceptable
*/
function Controls() {

    // states
    const [ tableView, setTableView ] = useState(false);
    const [ TWA, setTWA ] = useState(false);
    const [ changeAlert, setChangeAlert ] = useState(false);
    const [ lc, setLC ] = useState(); // new LC that will be saved
    const [ loaded, setLoaded ] = useState(false); 

    // TEMPORARY PATCH
    const [ nloads, setnloads ] = useState(0);

  
    // constants
    const context = useContext(AppContext);
    const controls = require('../json/controls.json');
    let { scenario } = useParams();
    let { task } = useParams();


    scenario = context.data.scenarios.find(o => o.id === parseInt(scenario));
    task = {...context.data.tasks.find(o => o.id === parseInt(task))};
    task.art = JSON.parse(task.art);
    task.exposure = JSON.parse(task.exposure);
    const substance = context.data.substances.find(o => o.id === scenario.substance);

    // props for controls-view-default and controls-view-table
    let exposure = task.exposure[context.data.ihcs] * 1000;

    // if TWA -> then calculate risk using TWA exposure (task * duration / 480)
    if (TWA) exposure = exposure * task.duration / 480;

    let oel = substance.oel;
    if (substance['oel_unit'] === 'mg/m3') oel *= 1000;
    if (substance['oel_unit'] === 'ng/m3') oel /= 1000000;

    
    useEffect(() => {
        if (!lc) {
            // on load set lc state
            task.lc ? setLC(task.lc) : null;
            setLoaded(true);
            setTWA(task.twa)
        }
        if (localStorage.getItem("show-table")) {
            setTableView(true);
            localStorage.removeItem("show-table");
        }
    }, [])


    useEffect(() => {
        setnloads(nloads+1);
        if (exposure && nloads > 1) {
            let controlItemSelected = false;

            for (let i in controls) {
                let risk = controls[i].score * exposure / oel >= 1;
                controls[i].risk = risk;

                if (!risk && !controlItemSelected) {
                    controlItemSelected = controls[i].key;
                    setLC(controls[i].key);
                    
                    if(nloads > 1) {
                        setChangeAlert(true);
                    }
                }
            }
        }
    }, [TWA])

    
    if (!loaded) return <Spinner />

    // TWA estimates are only allowed if scenario includes one task
    // otherwise this feature is disabled
    const twaEnabled = context.data.tasks.filter(o => o.scenario === scenario.id).length === 1;

    // calculate risk for each control
    // control multipliers are sometimes different for vapours and particles
    let score = 'score';

    


    // Button save - on click
    // update LC in the existing task
    const updateLCinTask = arg => {
        setLoaded(false);
        axios({
            url: `${context.API}/tasks/${task.id}/`,
            method: 'patch',
            data: {lc: arg ? arg : lc, twa: TWA},
            headers: {...context.data.headers}
        }).then(
            () => {
                message.success("LC updated!");
                context.update();
                setLoaded(true);
            }
        ).catch(
            () => message.error("ERROR OCCURED")
        )
    }


    
    return(
        <div>
            <Navigation
                title="IH control strategies"
                subTitle="Results"
                customNavigation={
                    <div>
                        <Link to={`/scenario/stype=0-es=${scenario.id}`}>
                            <ThemedButton
                                type="link-outlined"
                                label={<span><CloseOutlined /> Close</span>}
                            />
                        </Link>
                        <Divider type="vertical" className={ context.data.company } />
                        <ThemedButton
                            type="primary"
                            label={<span><SaveOutlined /> Save</span>}
                            onClick={() => updateLCinTask(lc)}
                        />
                    </div>
                }
            />
         
            <main>
                <Row justify='space-between'>
                    <Col style={{ fontSize: 22 }}>
                        <div>
                            <span>{ task.name }</span>
                            <Divider className={context.data.company} type='vertical' />
                            <span><ClockCircleOutlined /> {task.duration}min</span>
                        </div>
                    </Col>
                    <Col>
                        {
                            twaEnabled
                            ? <Form>
                                <Form.Item name="twa">
                                    <Space>
                                        <input 
                                            onChange={ () => { setTWA(!TWA); setnloads(nloads+1) }}
                                            type="checkbox"
                                            defaultChecked={TWA}
                                        />
                                        <label className="checkbox-label">TWA-based assessment</label>
                                    </Space>
                                </Form.Item>
                            </Form>
                            :<div />
                        }
                    </Col>
                    <Col>
                        {
                            exposure * 0.0001 > oel
                            ? <div />
                            : <Form>
                                <Form.Item name="tableView">
                                    <Space>
                                        <input 
                                            onChange={()=>setTableView(!tableView)}
                                            type="checkbox"
                                            checked={tableView}
                                        />
                                        <label className="checkbox-label">Table-view</label>
                                    </Space>
                                </Form.Item>
                            </Form>
                        }
                    </Col>
                </Row>
                <br/>

                {
                    TWA
                    ? <Alert
                        type="warning"
                        message="Warning!"
                        description={`When selecting this option, ${context.data.company === 'takeda' ? 'TAKTICS' : 'FERIHCS'} will assume no further exposure for the duration of the shift.  Apply this option only if it can be confirmed that there is no potential for workers to be exposed to the substance again during the same working shift.`}
                        showIcon
                    />
                    : <div />
                }
                <br/>

                {
                    changeAlert
                    ? <div style={{ marginBottom: 25 }}>
                        <Alert
                            type="warning"
                            message="LC modified"
                            description="To make your changes permanent, you must click on the Save button in navigation"
                            showIcon
                        />
                    </div>
                    : <div />
                }

                {/*
                    Controls for a single task
                    - default view
                    - table view

                    depending on the state of this component
                */}

                <div>
                    {
                        tableView
                        ? <ControlsViewTable 
                            exposure={exposure}
                            oel={oel} score={score}
                            onControlChange={value => {setLC(value); setTableView(false); setChangeAlert(true)} }
                            lc={lc}
                        />
                        : <ControlsViewDefault
                            exposure={exposure}
                            oel={oel}
                            score={score} 
                            onControlChange={value => { setLC(value); setChangeAlert(true) }}
                            lc={lc}
                            saveInitialLC={updateLCinTask}
                        />
                    }
                </div>
            </main>
        </div>
    )
}
export default Controls;