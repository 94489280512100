import { Alert } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import CS5 from './CS5';
import ThemedButton from './ThemedButton';









// default view for IH control strategies
// lists recommended controls for a single task
// based on the Word document established by Brian - Takeda
// received via email on the April 7th 2021
function ControlsViewDefault(props){

    // states
    // object element from array = controls-json
    const [state, setState] = useState({});

    // constants
    const controls = require('../json/controls.json');
    const labels = require('../json/labels.json');
    const oel = props.oel;
    const exposure = props.exposure;
    const score = props.score;

    // task
    // PATCH 13 MAY 2021
    // Bayes results
    let posterior = '';

    if (!props.report) {
        const context = useContext(AppContext);
        let { task } = useParams();
        task = {...context.data.tasks.find(o => o.id === parseInt(task))};
        posterior = JSON.parse(task.posterior);
    }


    // risk = true if >= 1 exposure/oel 
    // otherwise (i.e. false) the exposure is below OEL = no risk
    // first time risk is false, the corresponding CS is recorded
    let controlItemSelected = false;
    let cs = false;
    let lc = false;
    let cpt = false;

    for (let i in controls) {
        let lcFactor = (
            posterior[controls[i].key] && !props.report
            ? posterior[controls[i].key]['p95'] * 1000 / exposure
            : 1
        );

        let risk = lcFactor * controls[i][score] * exposure / oel >= 1;
        controls[i].risk = risk;

        if (!risk && !controlItemSelected) {
            controlItemSelected = controls[i].key;
            cs = controls[i].cs;
            lc = controls[i].lc;
            cpt = controls[i].cpt;
        }
    }


    // now let's filter all LC under identified CS-1
    let controlsToDisplay = [];
    if (cs) {
        controlsToDisplay = controls.filter(o => o.cs === cs);
    }
  

    // use effect
    // to make possible to user to select another control
    // this will update the image and description
    useEffect(() => {  
        // if task already includes lc
        let error = false;
        let controlObj = '';
        
        if (props.lc) {
            controlItemSelected = props.lc;
        } else if (props.saveInitialLC) {
            props.saveInitialLC(controlItemSelected);
        }

        
        
        if (!error) {
            controlObj = controls.find(o => o.key === controlItemSelected);
            controlsToDisplay = controls.filter(o => o.cs === controlObj.cs);
        }
        

        // change on load
        if (controlObj) {
            setState({ 
                ...state,
                controlObj: controlObj,
                img: controlObj.img[0],
                control: controlItemSelected,
                loaded: true,
                controlsToDisplay: controlsToDisplay
            });
        } else {
            setState({ 
                ...state,
                noControl: true,
                loaded: true
            });
        }
    }, [props])


    if (!state.loaded) {
        return <div>Please wait</div>;
    }

    // if DB (CS-5) evaluated -> render CS-5 component
    if (state.control === 'db1') {
        return <CS5 exposure={exposure} oel={oel} />
    }


    // no control can provide enough protection
    if (state.noControl) {
        return (
            <div>
                <Alert
                    type="warning"
                    message="No control found"
                    description="No local control can provide sufficient protection. 
                                Other measures, such as, for example,
                                use of less toxic or more dilluted substance, should be considered."
                    showIcon
                />
            </div>
        )
    }


    const trStyle = { borderTop: '1px solid rgba(200,200,200,0.1)' }


    return(
        <div>
            <table>
                <thead>
                    <tr style={trStyle}>
                        <th colSpan="3" style={{ textAlign: 'center' }}>
                            { labels[`cs${state.controlObj.cs}`] }
                        </th>
                    </tr>
                    <tr style={trStyle}>
                        <th colSpan="3" style={{ textAlign: 'center' }}>
                            <span>{ labels[state.controlObj.lc] }</span>
                        </th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr style={trStyle}>
                        <td width='35%' style={{ verticalAlign: "top" }} hidden={props.report}>
                        {
                            state.controlsToDisplay.map(
                                (item, inx) => (
                                    <div key={item.key}>
                                        {
                                            state.controlObj.cs === 3 && inx === 0 && !props.report
                                            ? <div style={{ marginBottom: 10 }}>
                                                { labels[state.controlObj.lc] }:
                                            </div>
                                            : <div />
                                        }

                                        {
                                            state.controlObj.cs === 3 && inx === 2 && !props.report
                                            ? <div style={{ marginBottom: 10, marginTop: 25 }}>
                                                Simple containment:
                                            </div>
                                            : <div />
                                        }
                                        
                                        {
                                            !props.report
                                            ? <ThemedButton
                                                type={ item.risk ? 'danger-outline' : 'success-outline' }
                                                className={state.control === item.key ? 'control-selected' : ''}
                                                disabled={ item.risk }
                                                label={ labels[item.key] }
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    minHeight: props.report ? 70 : 50,
                                                    marginBottom: 2 
                                                }}
                                                onClick={() => { 
                                                        setState({...state, control:item.key});
                                                        props.onControlChange(item.key)}}
                                            />
                                            : ''
                                        }
                                    </div>
                                )
                            )    
                        }
                        </td>
                        <td style={{ verticalAlign: "top" }} colSpan={props.report ? 3 : 1}>
                            <div>
                                {
                                    props.report && state.control
                                    ? <div style={{ 
                                            color: '#52c41a',
                                            marginBottom: 25,
                                            textAlign: 'center',
                                            fontSize: 18,
                                            fontWeight: 700
                                        }}>
                                        { labels[state.control] }
                                    </div>
                                    : ''
                                }
                                {
                                    state.controlObj.img.length > 1 && !props.report
                                    ? <div 
                                        style={{ 
                                            borderBottom: '1px solid rgba(200,200,200,0.1)',
                                            marginBottom: 10
                                        }}
                                    >{
                                        state.controlObj.img.map(
                                            (item, inx) => (
                                                <img
                                                    key={inx}
                                                    src={`/img/controls/${item}.png`}
                                                    alt="no img"
                                                    height="75"
                                                    style={{
                                                        padding: 5,
                                                        border: '1px solid rgba(200,200,200,0.1)',
                                                        marginRight: 2
                                                    }}
                                                    className="img-selectable"
                                                    onClick={() => setState({...state, img: item})}
                                                />
                                            )
                                        )
                                    }
                                    <br/>
                                    </div>
                                    : <div />
                                }
                                
                                {
                                    props.report 
                                    ? state.controlObj.img.map(
                                            (item, inx) => (
                                                <img
                                                    key={inx}
                                                    src={`/img/controls/${item}.png`}
                                                    alt="no img"
                                                    height="250"
                                                    style={{ marginLeft: 3 }}
                                                />
                                            )
                                        )
                                    :<img 
                                        src={`/img/controls/${state.img}.png`}
                                        alt="No img" 
                                        width={350}
                                    />
                                }
                            </div>
                        </td>
                        {!props.report 
                            ?<tr>
                                <td>{ DATA.LCinfo[state.control] }</td>
                            </tr>
                        : ''}
                    </tr>

                    {props.report 
                    ?<tr>
                        <td colSpan="3">{ DATA.LCinfo[state.control] }</td>
                    </tr>
                    : ''}

                    <tr>
                        <th 
                            colSpan="3" 
                            style={{ 
                                textAlign: 'center',
                                borderTop: '1px solid rgba(200,200,200,0.1)',
                                borderBottom: '1px solid rgba(200,200,200,0.1)' 
                        }}>Connection / Processing / Transfer points</th>
                    </tr>

                    <tr>
                        <th>{ cpt ? labels[state.controlObj.cpt] : "N/A" }</th>
                        <td colSpan="2">{ DATA.cpt[state.controlObj.cpt] }</td>
                    </tr>

                    <tr>
                        <th 
                            colSpan="3" 
                            style={{ 
                                textAlign: 'center',
                                borderTop: '1px solid rgba(200,200,200,0.1)',
                                borderBottom: '1px solid rgba(200,200,200,0.1)' 
                        }}>Facility design recommendations</th>
                    </tr>

                    <tr style={trStyle}>
                        <th>Workspace layout</th>
                        <td colSpan="2">{ labels[`wl-cs${state.controlObj.cs}`] }</td>
                    </tr>

                    <tr style={trStyle}>
                        <th>Airlocks</th>
                        <td colSpan="2">{ labels[`airlocks-cs${state.controlObj.cs}`] }</td>
                    </tr>

                    <tr style={trStyle}>
                        <th>Pressure cascade</th>
                        <td colSpan="2">{ labels[`pc-cs${state.controlObj.cs}`] }</td>
                    </tr>

                    <tr style={trStyle}>
                        <th>HVAC - filter replacement</th>
                        <td colSpan="2">{ labels[`hvac1-cs${state.controlObj.cs}`] }</td>
                    </tr>
                    
                    <tr style={trStyle}>
                        <th>HVAC - recirculation</th>
                        <td colSpan="2">{ labels[`hvac2-cs${state.controlObj.cs}`] }</td>
                    </tr>

                    <tr style={trStyle}>
                        <th>HVAC - exhaust air from rooms</th>
                        <td colSpan="2">{ labels[`hvac3-cs${state.controlObj.cs}`] }</td>
                    </tr>

                </tbody>
            </table>
        </div>
    )
}
export default ControlsViewDefault;




// Data to fulfill the table above
const DATA = {
    LCinfo: {
        gv: (
            <div>General ventilation offers no exposure protection at source and can only serve to dissipate and dilute contaminants released into the workplace atmosphere. It is therefore not considered as a local control option. General ventilation may be either natural or mechanical. Mechanical ventilation is the norm in the pharmaceutical industry and is often dictated by GMP requirements.  Nonetheless, general ventilation should be in the order of 4-8 air changes as a minimum for occupied workplaces where hazardous substances are handled.</div>
        ), lev1: (
            <div>
                A canopy hood over a hot process is a classic receiving hood. The process usually takes place outside the hood. The hood receives the contaminant cloud, which has a speed and direction that is usually heat generated. 
            </div>
        ), lev2: (
            <div>
                <p>The process, source and contaminant cloud are outside the hood. A capturing hood has to generate sufficient airflow at and around the source to ‘capture’ and draw in the contaminant-laden air.</p>
                <p>Movable LEV systems such as hoods with extendable arms. The design of the system does not prevent work being performed outside the capture zone of the system and worker behaviour can influence the effectiveness of the system.</p>
                <p><strong>Variations</strong>: See Fixed Capturing Hoods</p>
            </div>
        ), lev3: (
            <div>
                <p>
                    The process usually takes place outside the hood. The hood receives the contaminant cloud, which has a speed and direction that is usually process-generated. The face of the hood must be big enough to receive the contaminant cloud and the extraction empties the hood of contaminated air at least as fast as it is filled.
                </p><p>
                    <strong>Variants</strong>: A push-pull system is a special type of receiving hood.
                </p>
            </div>
        ), lev4: (
            <div>
                <p>
                    In a walk-in laminar down flow booth (DFB), a curtain of descending laminar air flow is created between the ceiling and the rear of the booth where exhaust grills are located in the lower section. The booth contains the source and has maximally one side open.
                </p><p>
                    <strong>Variants</strong>: Booths may also rely principally on airflow from the booth face (bottom image) OR may extract air from the booth floor.

                </p>
            </div>
        ), lev5: (
            <div>
                <p>
                    The process, source and contaminant cloud are outside the hood. A capturing hood has to generate sufficient airflow at and around the source to ‘capture’ and draw in the contaminant-laden air. 
                </p><p>
                    The hood is fixed and could be designed in various shapes e.g. round, rectangular or fitted around the source e.g. semi-circular for drums. They include rim/lip extraction (slot), downdraught tables or benches and LVHV (low volume high velocity) hoods.
                </p><p>
                    <strong>Variants</strong>: On-tool extraction e.g. soldering.
                </p>
            </div>
        ), lev6: (
            <div>
                <p>
                    In a walk-in laminar down flow booth (DFB), a curtain of descending laminar air flow is created between the ceiling and the rear of the booth where exhaust grills are located in the lower section. The booth contains the source and has maximally one side open.
                </p><p>
                    In this case a ‘high-containment screen’ with glove ports is included, providing further separation between operators and exposure source.
                </p><p>
                    <strong>Variants</strong>: Booths may also rely principally on airflow from the booth face (bottom image) OR may extract air from the booth floor.
                </p>
            </div>
        ), lev7: (
            <div>
                <p>
                    In a horizontal laminar flow booth, contaminated air is extracted through holes situated at the rear of the booth which creates a horizontal laminar air flow. The air is filtered prior to being discharged to the atmosphere. The booth contains the source and has maximally one side open.
                </p><p>
                    <strong>Note</strong>: This category expressly excludes walk-in booths, which are covered elsewhere.
                </p>
            </div>
        ), ve1: (
            <div>
                <p>Any form of permanent encapsulation or encasing of the source of which maximally one side is open with a well designed local exhaust ventilation system (e.g. laminar air flow). The design of both the enclosure and the ventilation system is such that the influence of worker behaviour is minimal (e.g. an alarm system prevents the worker from using the fume cupboard in case the system is not working properly).</p>
                <p><strong>Fume Cupboards/Fume Hoods</strong>: Typically for handling of general laboratory scale chemicals, which may also include active pharmaceutical ingredients.</p>
                <p><strong>Weighing Cabinets</strong>:  Ideal for handling of lab scale potent compounds in powder form. Typically lower face velocities to reduce interference with balance.</p>
                <p><strong>Biosafety Cabinets</strong>: Ideally applied in accordance with biosafety principles but may also provide adequate control for laboratory scale handling of drug substance containing liquids which may generate droplet aerosols.</p>
                <p><strong>Note</strong>: For substances with OELs &#62; 1 ug/m3 extracted air may be recirculated into the workplace, providing it is sufficiently filtered.</p>
                <p>For substances with an OEL &#60; 1ug/m3, extracted air may not be recirculated and must be vented to atmosphere following filtration.</p>
            </div>
        ), ve2: (
            <div><p>In a horizontal laminar flow booth, contaminated air is extracted through holes situated at the rear of the booth which creates a horizontal laminar air flow. The air is filtered prior to being discharged to the atmosphere. </p>
                <p>In this case there is a full or partial screen covering the front of the process/booth and is fitted with glove ports.  </p>
                <p><strong>Note</strong>: This is not a walk-in booth!</p>
                <p><strong>Note</strong>: For substances with OELs &#62; 1 ug/m<sup>3</sup> extracted air may be recirculated into the workplace, providing it is sufficiently filtered.</p>
                <p>For substances with an OEL &#60; 1ug/m<sup>3</sup>, extracted air may not be recirculated and must be vented to atmosphere following filtration.</p>
            </div>
        ), sc1: (
            <div>
                <p>Large plastic bags, available in different design and sizes are fitted with gloves which allow products to be handled in a contained way without exhaust ventilation.</p>
                <p>Glove bags are intended for ad-hoc one-time uses and are not intended as a long-term containment solution.</p>
            </div>
        ), sc2: (
            <div>
                <p>Physical containment or enclosure of the source of emission. The air within the enclosure is not actively ventilated or extracted. The enclosure is not opened during the activity.</p>
                <p><strong>Note</strong>:  This local control is intended to represent any form on simple containment or enclosure which is not covered by other listed local control options.  It is therefore a generic category.</p>
            </div>
        ), sbc1: (
            <div>
                <p>Large plastic bags, available in different design and sizes are fitted with gloves which allow products to be handled in a contained way. 
                </p>
                <p>
                In this case, the glove bag is maintained with filtration and ventilation at specific flow rates, maintaining a negative pressure. (Not shown in images!)
                </p>
                <p>
                Glove bags are intended for ad-hoc one-time uses and are not intended as a long-term containment solution.
                </p>
                <p>
                <strong>Note</strong>: For substances with OELs &#62; 1 ug/m3 extracted air may be recirculated into the workplace, providing it is sufficiently filtered.
                </p>
                <p>
                For substances with an OEL &#60; 1ug/m3, extracted air may not be recirculated and must be vented to atmosphere following filtration.
                </p>
            </div>
        ), sbc2: (
            <div>
                <p>
                    Closed RABS (restricted access barrier system) is a positive pressure system with on board fan/filtration units to supply HEPA filtered air over the critical process before being recirculated. In contrast to open RABS, it also provides exposure control as air is filtered prior to recirculation.
                </p><p>
                    cRABS  have rigid  transparent screens (polycarbonate or glass), equipped with glove flanges and gloves. cRABS doors are only opened at the start of an operation to perform equipment setup, and must be locked thereafter until the conclusion of operations.
                </p><p>
                    <strong>Note</strong>: Open RABS are not considered in the control scheme.  While they also provide a physical barrier, open RABS are aimed at product protection only and effectively blow air out of the RABS into the working environment.
                </p><p>
                    <strong>Note</strong>: Recalculation of extracted air into workplace is not permitted.
                </p>
            </div>
        ), sbc3: (
            <div>
                <p>Any form of permanent encapsulation or encasing of the source with a well designed local exhaust ventilation system.  The design of both the enclosure and the ventilation system is such that the influence of worker behaviour is minimal (e.g. the enclosure cannot be opened before the substance is properly vented).</p>
                <p>Low Specification Means:</p>
                
                <p><ul>
                    <li>Single chamber, simple access doors or pass box</li>
                    <li>Not safe change glove</li>
                    <li>Single HEPA filtered extract air (may be recirculated after HEPA)</li>
                    <li>Not safe change filters</li>
                    <li>Manual cleaning</li>
                </ul></p>

                <p><strong>Note</strong>: Flexible isolators are considered equivalent to low and medium specification rigid isolators, provided the match the features defined above and reliable containment verification data is available.</p>
                <p><strong>Note</strong>: Recalculation of extracted air into workplace is not permitted.</p>
            </div>
        ), sbc4: (
            <div>
                <p>Any form of permanent encapsulation or encasing of the source with a well designed local exhaust ventilation system.  The design of both the enclosure and the ventilation system is such that the influence of worker behaviour is minimal (e.g. the enclosure cannot be opened before the substance is properly vented).</p>
                <p>
                Medium Specification Means: </p><p><ul>
                <li>Two or more chambers if large area bin docking or high dust levels expected</li>
                <li>Safe change or push through filters are required</li>
                <li>Solid (stainless steel) construction for durability</li>
                <li>Air should be single HEPA filtered and or exhausted directly to atmosphere  after single HEPA filtration.</li>
                <li>The equipment should be maintained under negative pressure and the air flow and filter condition continuously monitored. </li>
                <li>Emergency air extraction should start up automatically in the event of a leak or a damaged glove. </li>
                <li>Interlocked air locks should be used to prevent high dust concentrations in
                the area of the transfer ports and reduce risk. (Or escape of the contaminant during transfer of materials into and out of the glove box). </li>
                <li>Glove changes should be able to be carried out without breaking containment</li>
                <li>Waste disposal ports are required. </li>
                <li>Correct sealing of continuous liners. </li>
                <li>Manual cleaning</li></ul></p>
                <p><strong>Note</strong>: Flexible isolators are considered equivalent to low and medium specification rigid isolators, provided the match the features defined above and reliable containment verification data is available.</p><p>
                <strong>Note</strong>: Recalculation of extracted air into workplace is not permitted.</p>
            </div>
        ), sbc5: (
            <div>
                <p>Any form of permanent encapsulation or encasing of the source with a well designed local exhaust ventilation system.  The design of both the enclosure and the ventilation system is such that the influence of worker behaviour is minimal (e.g. the enclosure cannot be opened before the substance is properly vented).</p>
                <p>High Specification Means: </p>
                <ul><li>Two or more chambers</li>
                <li>Safe change filters are required</li>
                <li>Stainless steel construction</li>
                <li>Safe change filters are required</li>
                <li>Air should be single or double HEPA filtered and or exhausted directly to the atmosphere after single HEPA filtration. </li>
                <li>The equipment should be maintained under negative pressure and the air flow and filter condition continuously monitored. </li>
                <li>Emergency air extraction should start up automatically in the event of a leak or a damaged glove. </li>
                <li>Interlocked air locks should be used to prevent the escape of the contaminant during transfer of materials into and out of the glove box. </li>
                <li>Glove changes should be able to be carried out without breaking containment</li>
                <li>Integrated sampling and contained drum charging</li>
                <li>Sealed and high containment transfer ports (contained transfer couplings, rapid transfer ports (RTPs), alpha/beta valves, etc.) </li>
                <li>Including waste removal and change parts</li>
                <li>Wash in place</li>
                <li>Alarmed</li></ul>
                <p><strong>Note</strong>: Recalculation of extracted air into workplace is not permitted.</p>
            </div>
        )
    },
    cpt: {
        low: (
            <div>
                <p>This class includes non-sealed,
                    non-ventilated and non-air-tight containment
                    options for example:
                </p>
                <ul>
                    <li>
                        Lids or covers
                    </li>
                    <li>
                        Simple material chutes fitted around transfer points from source to receiving vessel
                    </li>
                    <li>
                        Bags or liners fitted around transfer points from source to receiving vessel
                    </li>
                    <li>
                        Simple clamps and /or tie-offs
                    </li>
                </ul>
            </div>
        ),
        medium: (
            <div>
                <p>
                    The material transfer is enclosed with the
                    receiving vessel being docked or sealed to
                    the source vessel. Examples include sealing
                    heads, transfer containers and multiple o-rings.
                    Inflatable packing head with continuous liner
                    ensures a seal is maintained during the powder
                    transfer and the continuous plastic liner prevents
                    direct contact with the product. Liner tie off
                    must be used (crimping systems with proven
                    containment performance).
                </p>
                <p>
                    <strong>Note:</strong> Such transfer systems may not be required
                    when control systems such as ventilated enclosures
                    or glove bags are used, since materials typically
                    arrive in closed containers which are only opened
                    within the enclosures / cabinets.
                </p>
            </div>
        ),
        high: (
            <div>
                <p>
                    The material transfer is entirely enclosed with
                    high containment valves (e.g. split butterfly
                    valves and direct couplings, consisting of alpha-beta
                    parts which fit together to allow unlocking of the valve).
                    At the end of the material transfer the two halves are
                    separated, forming a seal on both the process equipment
                    and the material container. The system is designed to
                    minimise the surface area which can contact the material
                    or pairs of valves with wash space between them. In addition,
                    continuous liner systems should be secured with multiple
                    o-rings and/or clamps and a higher-grade crimping device
                    with proven containment performance should be used.
                </p>
            </div>
        )
    }
}


