import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Alert, Form, message, Result } from "antd";
import ThemedButton from './ThemedButton';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';


function PwChange() {

    const [ state, setState ] = useState('request');
    const context = useContext(AppContext);

    const form1 = (
        <Form
            onFinish={
                values => {
                    setState('loading');
                    axios.post(
                        `${context.API}/set-pw-token/`,
                        { email: values.email.toLowerCase() }
                    ).then(
                        () => setState('token')
                    ).catch(
                        () => setState('error')
                    )
                }
            }
        >
            <div style={{ marginBottom: 25, fontWeight: 'bold' }}>
                Please enter your email. A token will be sent to you by email (please also check your SPAM folder)
            </div>
            <Form.Item
                required
                name="email"
                type="email"
                label="Your e-mail"
                rules={[{required: true, message: "Email required!"}]}
            >
                <input type="email" />
            </Form.Item>

            <Form.Item>
                <ThemedButton
                    buttonType="submit"
                    type="primary" 
                    label="Request New Password" 
                />
            </Form.Item>
        </Form>
    )

    const form2 = (
        <Form 
            labelCol={{ md: 8, xs: 24 }}
            labelAlign="left"
            onFinish={
                values => {
                    axios.post(
                        `${context.API}/pw-reset/`,
                        {...values}
                    ).then(
                        () => {
                            message.success("Password changed with success!");
                            setState('success');
                        }
                    ).catch(
                        () => setState('error')
                    )
                }
            }
        >
            <Form.Item
                required
                name="email"
                type="email"
                label="Your e-mail"
                rules={[{required: true, message: "Email required!"}]}
            >
                <input type="email" />
            </Form.Item>

            <Form.Item
                required
                name="token"
                label="Received Token"
                rules={[{required: true, message: "Token required!"}]}
            >
                <input type="text" />
            </Form.Item>

            <Form.Item
                required
                name="password"
                label="New password"
                rules={[{required: true, message: "Password required!"}]}
            >
                <input type="password" />
            </Form.Item>

            <Form.Item
                required
                name="password2"
                type="password"
                label="Repeat password"
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password2') === getFieldValue('password') ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords don\'t agree'));
                        },
                    }), { required: true, message: "Password is required" }
                ]}
            >
                    <input type="password" />
            </Form.Item>

            <Form.Item>
                <ThemedButton
                    buttonType="submit"
                    type="primary" 
                    label="Submit" 
                    onClick={() => null}
                />
            </Form.Item>
        </Form>
    )

    // spinner while waiting response
    if (state === 'loading') {
        return (
            <Spinner />
        )
    }

    if (state === 'error') {
        return (
            <div>
                <Alert 
                    type="error"
                    message="Error occured"
                    description="Please check if you have entered the correct email"
                />
            </div>
        )
    }

    // password changed
    if (state === 'success') {
        return (
            <div>
                <Result
                    status="success"
                    title="Successfully Changed Password!"
                    subTitle="You can now login using your new password"
                    extra={[
                    <Link to="/login">
                        <ThemedButton type="primary" label="Login" />
                    </Link>,
                    ]}
                />
            </div>
        )
    }

    return (
        <div style={{
            paddingTop: 50,
            width: 500,
            margin: "auto"
        }}>
            { state === 'token' ?
                <div>
                    <div>
                        A confirmation token has been sent to you by email and must be entered in the form bellow. 
                        Please check your SPAM folder as well and don't close this page.
                    </div>
                    <br/>
                    <div>{form2}</div>
                </div> 
                : form1 
            }
        </div>
    )
}
export default PwChange;