import { Col, Row, Statistic } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ThemedButton from './ThemedButton';



/*
    Page header:
        - Add new button
        - Search items
        - Statistics
*/
function PageHeader(props) {
    return(
        <div>
            <Row gutter={16} justify="space-between">
                <Col>
                    <Link to={props.link}>
                        <ThemedButton type="primary" 
                            label={props.buttonLabel}
                        />
                    </Link>
                </Col>
                <Col md={16}>
                    <input 
                        placeholder={props.searchPlaceholder}
                        onChange={props.onSearch}
                        disabled={props.searchDisabled}
                    />    
                </Col>
                <Col>
                    <Statistic 
                        title={props.statisticsTitle}
                        value={props.statisticsValue}
                        prefix={props.statisticsPrefix}
                        suffix={props.statisticsSuffix}
                    />
                </Col>
            </Row>

            <div>
                <p style={{
                    fontSize: 26
                }}>{props.pageTitle}</p>
            </div>
        </div>
    )
}
export default PageHeader;