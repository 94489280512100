import { 
    CheckOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    PrinterOutlined 
} from '@ant-design/icons';
import React, { useContext, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';
import Navigation from './Navigation';
import { Alert, Divider, Result } from 'antd';
import ReactToPrint from 'react-to-print';
import ExposureTable from './ExposureTable';
import MyChart from './MyChart';
import ControlsViewDefault from './ControlsViewDefault';
import '../styling/reports.css';

const styling = {
    tdName: {
        width: 100
    },
    tdValue: {
        width: 300
    }
}





function Report() {

    const context = useContext(AppContext);
    const labels = require('../json/report.json');
    const rounding = context.functions.rounding;

    // get current date
    let dt = new Date();
    let dd = String(dt.getDate()).padStart(2, '0');
    let mm = String(dt.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = dt.getFullYear();

    dt = dd + '.' + mm + '.' + yyyy;


    // scenario id
    const { id } = useParams();
    const scenario = {...context.data.scenarios.find(o => o.id === parseInt(id))};
    let tasks = context.data.tasks.map(o => Object.assign({}, o));
    tasks = tasks.filter(o => o.scenario === parseInt(id));
    const stype = scenario['scenario_type'];
    const substance = () => {
        try {return(context.data.substances.find(o => o.id === scenario.substance))}
        catch(e) {return {name: 'private data'}}
    }
    let oel = substance.oel;
    if (substance['oel_unit'] === 'mg/m3') oel *= 1000;
    if (substance['oel_unit'] === 'ng/m3') oel /= 1000000;
    
    // percentile
    const preferredPerc = stype === 0 ? 'p95' : context.data.percentile;

    // ref
    const printRef = useRef();


    // return if no tasks
    // if no tasks -> well show alert
    if (tasks.length === 0) {
        return (
            <Result
                status="404"
                title="No tasks defined"
                subTitle="Sorry, no report can be generated for scenarios with no tasks included"
                extra={
                    <Link to={`/scenario/stype=${stype}-es=${id}`}>
                        <ThemedButton
                            type="primary"
                            label="Go back"
                        />
                    </Link>
                }
            />
        )
    }


    // info needed in report
    let totalDuration = 0;
    let twa = 0;
    let ci_lower = 0;
    let ci_upper = 0;
    let tasksExposures = [];

    for (let i in tasks) {
        tasks[i].art = JSON.parse(tasks[i].art)
        let exposure = JSON.parse(tasks[i].exposure);
        let duration = tasks[i].duration;
        totalDuration += duration;

        // twa
        let taskExp = exposure[preferredPerc] * 1000;
        twa += taskExp * duration / 480;
        ci_lower += 1000* exposure[`${preferredPerc}_${context.data.ci}_lower`] * duration / 480;
        ci_upper += 1000* exposure[`${preferredPerc}_${context.data.ci}_upper`] * duration / 480;
        tasksExposures.push(taskExp);

        // ihcs report
        tasks[i].ichsExposure = taskExp;
    }

    twa = rounding(twa);
    ci_lower = rounding(ci_lower);
    ci_upper = rounding(ci_upper);
   

    // inputs to print
    const printable = [
        'state', 
        'vp', 
        'dustiness', 
        'concentration',
        'info_substance',
        'nf',
        'ff',
        'activity',
        'det1',
        'det2',
        'det3',
        'info_activity',
        'volume',
        'ach',
        'lc',
        'dbc1',
        'dbc2',
        'info_controls'
    ];



    // generates one Tr for table (i.e. task)
    const getRow = (n, v) => {
        let name = labels[n] ? `${labels[n]}:` : '';
        let value = labels[v] ? labels[v] : v;
        
        // ignore if no value
        // for example, dustiness is missing for liquids
        if (v == undefined) {
            return ''
        }

        // if near or far-field
        if (['nf', 'ff'].includes(n)) {
            value = (
                value 
                ? <span><CheckOutlined /></span>
                : <span><CloseCircleOutlined /></span>
            )
        }

        return (
            <tr key={name}>
                <td style={styling.tdName}>{ name }</td>
                <td style={styling.tdValue}>{ value }</td>
            </tr>
        )
    }


    // PATCH: Error in report if no lc parameter
    let noLcFound = false;
    for (let i in tasks) {
        if (tasks[i].lc === "") {
            noLcFound = true;
        }
    }


    if (noLcFound && stype === 0) {
        return (
            <div>
                <Navigation
                    title="Exposure report"
                    subTitle="Print or download pdf"
                    customNavigation={
                        <div>
                            <Link to={`/scenario/stype=${stype}-es=${id}`}>
                                <ThemedButton 
                                    label={<span><CloseOutlined /> Close</span>}
                                    type="link-outlined"
                                />
                            </Link>
                            
                        </div>
                    }
                />
                <Alert
                    type='warning' 
                    message="Report cannot be printed"
                    description="For some tasks defined in this scenario, you have not ran 'Control strategies' (after the task was created or modified). Please go back (click on 'Close') and click on 'Control strategies' for each task defined."
                    showIcon={true}
                />
            </div>
        )
    }


    // otherwise we print report
    return(
        <div>
            <Navigation
                title="Exposure report"
                subTitle="Print or download pdf"
                customNavigation={
                    <div>
                        <Link to={`/scenario/stype=${stype}-es=${id}`}>
                            <ThemedButton 
                                label={<span><CloseOutlined /> Close</span>}
                                type="link-outlined"
                            />
                        </Link>
                        <Divider type="vertical" className={context.data.company} />
                        <ReactToPrint
                            bodyClass="print-report"
                            trigger={() => (
                                <ThemedButton 
                                    label={<span><PrinterOutlined /> Print (PDF)</span>}
                                    type="primary"
                                />
                            )}
                            content={() => printRef.current}
                        />
                    </div>
                }
            />

            

            <div ref={printRef}
                className="print-container"
                style={{ margin: "0", padding: "0" }}
            >
      
                <div className="page-break" />

                {/* Page 1 */}
                <div>
                    <div>
                        { 
                            context.data.company === 'takeda'
                            ? <div>
                                <div style={{ marginBottom: 20, fontSize: 18 }}>
                                    <strong>TAKTICS</strong> - Takeda Tool for Industrial Hygiene Control Strategies
                                </div>
                                <div>
                                    <img height="75" src='/img/Takeda.png' alt="No img" />
                                </div>
                            </div>
                            : <div>
                                <div style={{ marginBottom: 20, fontSize: 18 }}>
                                    <strong>FERIHCS</strong> - Ferring Industrial Hygiene Contol Strategies
                                </div>
                                <div>
                                    <img height="75" src='/img/Ferring.png' alt="No img" />
                                </div>
                            </div>
                        }
                    </div><br/>
                    <div>
                        <div>Created by: {context.data.email}</div>
                        <div>Report run: {dt}</div>
                    </div>
                    <br/>
                    <div className="report-title">{scenario.name}</div><br/><br/>
                    <div>Number of tasks: {tasks.length}</div>
                    <div>Total duration: {totalDuration} min</div>
                    <div>
                        { tasks[0].twa 
                            ? <div>
                                <br/>
                                <Alert
                                    type="warning"
                                    message="Time-weighted average (TWA) exposure estimates were used for control strategies recommendations for the single activity task of this exposure scenario"
                                    showIcon
                                />
                            </div> 
                            : ''
                        }
                    </div>

                    <br/>
                    <Divider className={context.data.company}>
                        Substance
                    </Divider>

                    <div>Name: {substance().name}</div>
                    <div>CAS-Nr.: {substance().cas}</div>
                    <div>OEL: {substance().oel} {substance()['oel_unit']}</div>

                    {
                        stype === 0
                        ? ''
                        : <div>
                            <Divider className={context.data.company}>
                                Time-weighted average exposure
                            </Divider>

                            <div>
                                Exposure, {labels[preferredPerc]}: {twa} &mu;g/m<sup>3</sup>
                            </div>
                            <div>
                                Confidence interval, {labels[context.data.ci]}: {ci_lower}-{ci_upper} &mu;g/m<sup>3</sup>
                            </div>
                        </div>
                    }

                </div>

                 {/* Page 2 */}
                <div className="page-break" />
                <div>{
                    stype === 0
                    ? ''
                    :<div>
                        <div>Exposure distribution - (percentiles | confidence intervals)</div>
                        <div>
                            <ExposureTable twa={true} scenario={parseInt(id)}/>
                        </div><br/><br/>

                        <div>Graphical illustration of important results</div>
                        <div style={{ width: 400 }}>
                            <MyChart
                                oel={substance.oel}
                                oelUnit={substance['oel_unit']}
                                twa={twa} 
                                tasks={tasksExposures}
                            />
                        </div>
                    </div>
                }</div>

                {/* Page 3 */}
                <div>
                { tasks.map( 
                    task => (
                        <div key={task.id}>
                            <div className="page-break" />
                            <Divider className={context.data.company}>
                                <span>{task.name} <ClockCircleOutlined /> {task.duration} min</span>
                            </Divider>
                            <table>
                                <tbody>{
                                    printable.map(
                                        name => getRow(name, task.art[name])
                                    )
                                }</tbody>
                            </table><br/>
                            
                            <div>{
                                stype === 0
                                ? <div>
                                    {/* only for IHCS scenarios */}
                                    <div className="page-break" />
                                    <ControlsViewDefault 
                                        oel={oel}
                                        score="score"
                                        lc={task.lc}
                                        exposure={task.ichsExposure}
                                        report
                                    />
                                </div>

                                
                                : <div>
                                    {/* only for Exposure assessment scenarios */}
                                    <div>Exposure distribution - task-based exposure estimates</div>
                                    <ExposureTable task={task.id} scenario={id}/>
                                </div>
                            }</div>
                        </div>
                    )
                ) }
                </div>
            </div>
        </div>
    )
}
export default Report;