import React from "react";
import AppContextProvider from "./AppContext";
import AppRouter from "./AppRouter";
import './styling/app.css';



function App() {
    return(
        <AppContextProvider>
            <AppRouter />
        </AppContextProvider>
      );
}

export default App;