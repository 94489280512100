import { Alert, message } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';




// verify my email
function VerifyMyEmail() {
    const [state, setState] = useState(false);
    const { token } = useParams();
    const { id } = useParams();
    const context = useContext(AppContext);

    useEffect(() => {
        axios.get(
            `${context.API}/verify-my-email/${token}/${id}/`
        ).then(
            res => {
                localStorage.setItem('feta-auth-token', res.data.token);
                message.success("Email confirmed");
                setState(true);
            }
        ).catch(
            () => message.error("ERROR OCCURED")
        )
    }, [])

    return(
        <div>
            {
                state === true
                ? <div> <br/><Alert
                    type="success"
                    message="Email confirmed!"
                    showIcon
                />
                    <br/>
                    <div style={{ textAlign: "center" }}>
                        <Link to="/login">
                            <ThemedButton
                                label="Login now!"
                                type="success"
                            />
                        </Link>
                    </div>
                </div>

                : <div>
                    <Alert
                        type="error"
                        message="Email confirmation failed!"
                        showIcon
                    />
                </div>

            }
        </div>
    )
}
export default VerifyMyEmail;