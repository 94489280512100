import { DeleteOutlined, EditOutlined, ExperimentOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, message, Empty, Alert } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Navigation from './Navigation';
import PageHeader from '../components/PageHeader';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';
import { Link } from 'react-router-dom';






// create new substance
// edit existing
// list of created substances
function Substances() {
    const context = useContext(AppContext);
    const role = context.data.roleObj;
    let company = context.data.company;
    const [searchedName, setSearchedName] = useState(false);
    let substances = [...context.data.substances];
    const labels = require('../json/labels.json');


    // convert OEL to ug/m3 (table displays OEL in same units)
    for (let i in substances) {
        let unit = substances[i]['oel_unit'];

        if (unit === 'mg/m3') {
            substances[i].oelf = substances[i].oel * 1000;
        } else if (unit === 'ng/m3') {
            substances[i].oelf = substances[i].oel / 1000;
        } else {
            substances[i].oelf = substances[i].oel;
        }

        // PATCH 04-MAY-2021
        // if . is the first character (still valid)
        let oelStr = substances[i].oelf.toString();
        if (oelStr[0] === '.') {
            oelStr = `0${oelStr}`;
            substances[i].oelf = oelStr;
        }
    }


    let dataSource = (
        searchedName
        ? [...substances.filter(sub => sub.name.match(searchedName))] 
        : [...substances]
    );




    // delete substance 
    const handleDelete = id => {
        axios.delete(
            `${context.API}/substances/${id}/`,
            {headers:  {...context.data.headers}}
        )
        .then(() => {
            message.warning("Substance deleted");
            context.update();
        })
        .catch(() => message.error("Error occured"))
    }



    for(let i in dataSource) {
        dataSource[i].actions = (
            <div>
                <Link to={`/substances/sub=${dataSource[i].id}`}>
                    <ThemedButton type="link" label={<EditOutlined />} />
                </Link>
                {
                    dataSource[i].user === role.user ?
                    <Popconfirm 
                        title="Are you sure?"
                        okText="Yes"
                        onConfirm={()=>handleDelete(dataSource[i].id)}
                    >
                        <ThemedButton type="link" label={<DeleteOutlined />} />
                    </Popconfirm>
                    : <div />
                }
            </div>
        )
    }


    // to know if it admin
    const isUserAdmin = [
        'admin-taktics@takeda.com',
        'admin-ferihcs@ferring.com'
    ].includes(localStorage.getItem('feta-user-email'));

    // PATCH: 11-Jun-2022: sorting alphabetically
    dataSource.sort(function(a, b) {
        let textA = a.name.toUpperCase();
        let textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    
    return(
        <div>
            <Navigation title="Substances" subTitle="List of Defined Substances"/>
            <PageHeader
                buttonLabel={<span><PlusOutlined /> New Substance </span>}
                searchPlaceholder="Substance Name"
                link="/substances/sub=0"
                onSearch={e => setSearchedName(e.target.value)}
                statisticsTitle="Substances"
                statisticsValue={substances.length}
                statisticsPrefix={<ExperimentOutlined />}
                statisticsSuffix="/1000"
                searchDisabled={substances.length === 0}
                pageTitle="Substances"
            />

            
            {
                substances.length === 0
                ? <Empty description="No Substances" />
                : <div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Action</th>
                                <th>Name</th>
                                <th>CAS-Nr.</th>
                                <th>OEL, μg/m3</th>
                                <th>Owner</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.map(
                                substance => (
                                    <tr key={substance.id}>
                                        <td>{substance.id}</td>
                                        <td>{substance.owner && !isUserAdmin
                                                ? <span style={{
                                                    color: 'red'
                                                }}>No permission*</span>
                                                : substance.actions
                                        }</td>
                                        <td>{substance.name}</td>
                                        <td>{substance.cas}</td>
                                        <td>{substance.oelf}</td>
                                        <td>{substance.owner 
                                                ? company[0].toUpperCase().concat(company.substr(1, company.length)) 
                                                : substance.private ? 'User' : <span style={{ color: 'red' }}>Site</span>
                                            }</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <br/>
            <p>* Users are not allowed to modify or delete the data entered by their company admins</p>
        </div>
    )
}
export default Substances;