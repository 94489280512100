import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Divider, message, Popconfirm, Row } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext'
import Spinner from './Spinner'
import ThemedButton from './ThemedButton'





// Page dedicated to Site manager
export default function SiteManagerPage() {
  
    const [state, setState] = useState({loaded: false})
    const [update, setUpdate] = useState(0)
    const context = useContext(AppContext)
    const api = context.API 
    const headers = context.data.headers
    const role = context.data.roleObj

    // GET site users
    // if any, joined to the site this user is managing
    useEffect(() => {
        axios.get(`${api}/get-site-users/`, {headers: headers})
        .then(res => setState({ loaded: true, siteUsers: res.data }))
        .catch(() => message.error("Failed to load data"))
    },[update])

    // if loaded false -> spin
    if (!state.loaded) {
        return (<div><Spinner /></div>)
    }
    
    return (
        <div>
             <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', border: '1px solid silver', padding: 10 }}>
                Before performing any actions, please read <Link to="/user-roles-info">the documentation</Link>
            </p>
            {
                role.status === 'accepted' || role.status === 'change_by_manager' ?
                <Alert
                    closable
                    onClick={() => {
                        axios.get(`${api}/reset-role-status/`, {headers: headers})
                        .then(() => console.log("Role status reset"))
                        .catch(()  => console.log("Role status reset failed"))
                    }}
                    style={{ marginBottom: 25 }}
                    showIcon
                    type="success"
                    message="Congratulations"
                    description="Your status have been upgraded to 'Site manager' by the App Manager.
                                This page lists requests sent by the users with the 'Regular user' role 
                                to join your site. Please make sure that they are informed about your new role
                                and the existence of this site."
                /> : <div />
            }
            
            <div>
                <strong>My site</strong>: { context.data.site.name } ({context.data.site.location})
            </div>
            <br/>

            {
                context.data.roleRequests.length > 0
                ? <div>
                    <div>
                        <Divider className={context.data.company}>Received Requests</Divider>
                        <table>
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Requested by</th>
                                    <th>Date of request</th>
                                    <th>Cover letter</th>
                                </tr>
                            </thead>
                            <tbody>{
                                context.data.roleRequests.map(
                                    req => (
                                        <tr key={req.id}>
                                            <td>
                                                <div>
                                                    <Popconfirm
                                                        placement='topRight'
                                                        title="Are you sure that you want to reject this request?"
                                                        onConfirm={() => {
                                                            axios.delete(`${api}/role-request/${req.id}/`, {headers: headers})
                                                            .then(() => {
                                                                message.warning("Request rejected");
                                                                context.update();
                                                            }).catch(() => message.error("Failed to reject"))
                                                        }}
                                                        okText="Yes, reject"
                                                        cancelText="No"
                                                    >
                                                        <ThemedButton
                                                            type="primary"
                                                            label={<DeleteOutlined />} 
                                                        />
                                                    </Popconfirm>

                                                    <ThemedButton
                                                        type="success"
                                                        label="Join"
                                                        onClick={() => {
                                                            axios.put(`${api}/role-request/${req.id}/`, 'site',{headers: headers})
                                                            .then(() => {
                                                                message.success("User joined your site");
                                                                context.update();
                                                            }).catch(() => message.error("Request failed"))
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>{req.user_email}</td>
                                            <td>{req.created}</td>
                                            <td>{req.message}</td>
                                        </tr>
                                    )
                                )
                            }</tbody>
                        </table>
                    </div>
                    <br/>
                </div>
                : <div />
            }

            <div>
                <Divider className={context.data.company}>My Team</Divider>

                {
                    state.siteUsers.length === 0
                    ? <div>
                        <p>Only me :(</p>
                        <p>
                            No user has joined your site yet. Please make sure that they are aware of this site.
                            They can request to join by sending requests from their accounts. All requests will be visible on 
                            this page. You can then approve or reject the requests received.
                        </p>
                    </div>
                    : <div>
                        {
                            state.siteUsers.map(
                                user => (
                                    <Row gutter={16} key={user.id}>
                                        <Col>
                                            <Popconfirm
                                                title="Are you sure?"
                                                placement='topRight'
                                                onConfirm={() => {
                                                    axios.delete(`${api}/disjoin-user-from-site/${user.id}/`, {headers: headers})
                                                    .then(() => {
                                                        message.warning("User disjoined!");
                                                        setUpdate(update+1);
                                                    })
                                                    .catch(() => message.error("Request failed"))
                                                }}
                                                okText="Yes, remove"
                                            >
                                                <Button type="primary" danger size='small'>
                                                    <CloseCircleOutlined/> Remove
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                        <Col>{user.email}</Col>
                                    </Row>
                                )
                            )
                        }
                    </div>
                }
            </div>
        </div>
    )
}