import React, { useContext, useState } from 'react';
import { Alert, Col, Form, message, Row } from 'antd';
import ThemedButton from './ThemedButton';
import axios from 'axios';
import { AppContext } from '../AppContext';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';







// login and sign-up page
function Login() {
    
    const [state, setState] = useState('login');
    const [loading, setLoading] = useState(false);
    const context = useContext(AppContext);
    

    // request token
    const handleSubmit = values => {
        axios.post(
            `${context.API}/api-token-auth/`,
            {...values, username: values.email}
        ).then(
            res => {
                localStorage.setItem('feta-auth-token', res.data.token);
                window.location.href = '/';
            }
        ).catch(
            () => message.error("Failed: wrong email or/and password")
        )
    }



    // form styling
    const styling = {
        border: '1px solid rgba(180, 180, 180, 0.8)',
        background: 'rgba(220, 220, 220, 0.1)',
        padding: 15,
        borderRadius: 5,
        marginTop: 50
    };


    const handleRegistration = values => {
        setLoading(true);
        axios.post(
            `${context.API}/new-user/`,
            {
                ...values, 
                username: values.email.toLowerCase(),
                email: values.email.toLowerCase()
            }
        ).then(
            res => {
                if (res.status === 208) {
                    // If already registered
                    message.warning("A user with this email aready registered")
                    setLoading(false)
                    return;
                } else {
                    message.success("You must confirm your email. Check your email!");
                    setLoading(false);
                    setState('login-post-sign-up');
                }
            }
        ).catch(
            e => {
                if (e.response) {
                    if (e.response.status === 403) {
                        message.warning("You are not allowed to register!");
                        setLoading(false);
                        return;
                    }
                    if (e.response.status === 408) {
                        message.warning("Request Timeout!");
                        setLoading(false);
                        return;
                    }
                } 
                message.error("ERROR OCCURED")
            }
        )
    }

    if (loading) return <Spinner />

    const LoginForm = (
        <div style={styling}>
            <Form 
                onFinish={ handleSubmit }
                initialValues={{ email: '' }}
            >
                <div style={{ fontSize: 22 }}>
                    <span>Login to {
                        context.data.company === 'takeda'
                        ? 'Taktics'
                        : ' Ferihcs'
                    }</span>
                </div><br/>
                <Form.Item
                    required
                    name="email"
                    type="email"
                    rules={[
                        { type:"email", message:"Not email" },
                        { required: true, message: "E-mail is required" }
                    ]}
                    label="E-mail address"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                >
                    <input type="text" />
                </Form.Item>
                <Form.Item
                    required
                    name="password"
                    type="password"
                    rules={[
                        { required: true, message: "Password is required" }
                    ]}
                    label="Password"
                    wrapperCol={{ span: 24 }}
                        labelCol={{ span: 24 }}
                    >
                        <input type="password" />
                </Form.Item><br/>
                <Form.Item wrapperCol={{md: { span: 10, offset: 7 }, xs:{span: 24}}}>
                    <ThemedButton 
                        buttonType="submit"
                        type="success"
                        label="Login"
                        style={{ width: '100%' }}
                    />
                </Form.Item>

            </Form>
            <Row justify='center'>
                <Col span={10}>
                    <ThemedButton
                        type="primary" 
                        label="Sign up" 
                        style={{ width: '100%' }}
                        onClick={() => setState('signup')}
                    />
                </Col>
            </Row><br/>

            <div>
                <Link to="/pw-reset">
                    <ThemedButton
                        type="link" 
                        label="Forgot password?" 
                        style={{ width: '100%', color: "red" }}
                    />
                </Link>
            </div>
            
            <div style={{ marginTop: 20, textAlign: 'center' }}>
                In case of troubles:
                <Link to="/contact">
                    <ThemedButton
                        type="link" 
                        label={<span><u>Contact support</u></span>} 
                        style={{ color: 'blue' }} 
                    />
                </Link>
            </div>
            
            <div>April 2023</div>
        </div>
    )




    // register new user
    const SignUpForm = (
        <Form
            style={styling}
            onFinish={handleRegistration}
        >
            <div style={{
                fontSize: 22
            }}>New user</div><br/>

            <Form.Item
                required
                name="email"
                type="email"
                rules={[
                    { type:"email", message:"Not email" },
                    { required: true, message: "E-mail is required" }
                ]}
                label="E-mail address"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
            >
                <input type="text" />
            </Form.Item>
            <Form.Item
                required
                name="password"
                type="password"
                rules={[
                    { required: true, message: "Password is required" }
                ]}
                label="Password"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}              
                >
                    <input type="password" />
            </Form.Item>
            <Form.Item
                required
                name="password2"
                type="password"
                label="Repeat password"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password2') === getFieldValue('password') ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords don\'t agree'));
                        },
                    })
                ]}
            >
                    <input type="password" />
            </Form.Item>
            <br/>
            <Form.Item wrapperCol={{ span: 10, offset: 7 }}>
                <ThemedButton 
                    buttonType="submit"
                    type="success"
                    label="Register"
                    style={{ width: '100%' }}
                />
            </Form.Item>
            
            <div style={{ marginTop: 20, textAlign: 'center' }}>
                In case of troubles:
                <Link to="/contact">
                    <ThemedButton
                        type="link" 
                        label={<span><u>Contact support</u></span>} 
                        style={{ color: 'blue' }} 
                    />
                </Link>
            </div>
        </Form>
    )


    return(
        <div className={`${context.data.theme} ${context.data.company}`}>
            
            <div style={{ marginTop: 10, textAlign: 'center' }}>
                <img 
                    src={
                            context.data.company === 'takeda'
                            ? '/img/Takeda.png'
                            : '/img/Ferring.png'
                        }
                    height={75}
                    alt="No img" 
                />
            </div>

            {
                state === 'login-post-sign-up'
                ? <Alert
                    type="warning"
                    message="An email has been sent"
                    description="You will receive soon an email with the confirmation link. Please also check your SPAM folder. In case you don't receive the link within the next 10 minutes, please contact the support (see bellow)"
                    showIcon
                /> : <div />
            }

            <div id="app">
                <Row>
                    <Col 
                        md={{ span: 8, offset: 8 }}
                        xs={{ span: 22, offset: 1 }}
                    >
                        {
                            state === 'login' || state === 'login-post-sign-up'
                            ? LoginForm
                            : SignUpForm
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Login;