import React, { useContext } from "react";
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import { AppContext } from "./AppContext";
import Controls from "./components/Controls";
import ScenariosList from "./components/ScenariosList";
import Scenario from "./components/Scenario";
import Task from './components/Task';
import Substance from "./components/Substance";
import Settings from "./components/Settings";
import Start from "./components/Start";
import Substances from "./components/Substances";
import Exposure from "./components/Exposure";
import withAuth from "./hoc/withAuth";
import Login from "./components/Login";
import Report from "./components/Report";
import ReportBug from "./components/ReportBug";
import VerifyMyEmail from "./components/VerifyMyEmail";
import PwChange from "./components/PwChange";
import Contact from "./components/Contact";
import SiteManagement from "./components/SiteManagement";
import Site from "./components/Site";
import UserRolesInfo from "./components/UserRolesInfo";


function AppRouter() {
    const context = useContext(AppContext);
    

    return(
        <div className={`${context.data.theme} ${context.data.company}`}>
            <BrowserRouter>
                <div id="app">
                    <Switch>
                        <Route path="/sites/site=:id" component={withAuth(Site)} />
                        <Route path="/user-roles-info" component={withAuth(UserRolesInfo)} />
                        <Route exact path="/site-management" component={withAuth(SiteManagement)} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/" component={withAuth(Start)} />
                        <Route exact path="/ihcs" component={withAuth(ScenariosList)} />
                        <Route exact path="/exposure" component={withAuth(ScenariosList)} />
                        <Route exact path="/substances" component={withAuth(Substances)} />
                        <Route exact path="/controls/es=:scenario-task=:task" component={withAuth(Controls)} />
                        <Route path="/substances/sub=:id" component={withAuth(Substance)} />
                        <Route path="/scenario/stype=:stype-es=:id" component={withAuth(Scenario)} />
                        <Route path="/task/scenario=:scenario-task=:task" component={withAuth(Task)} />
                        <Route exact path="/settings" component={withAuth(Settings)} />
                        <Route path="/exposure-estimates/es=:id" component={withAuth(Exposure)} />
                        <Route path="/report/es=:id" component={withAuth(Report)} />
                        <Route path="/login" component={Login} />
                        <Route path="/verify-my-email=token=:token-id=:id" component={VerifyMyEmail} />
                        <Route path="/report-a-bug" component={withAuth(ReportBug)} />
                        <Route exact path="/pw-reset" component={PwChange} />
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
      );
}
export default AppRouter;