

import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import Spinner from './components/Spinner';



export const AppContext = createContext({});


export default function AppContextProvider(props) {

    // context data
    const [ update, runUpdate ] = useState(0);
    const [ state, setState ] = useState({
        company: 'takeda',
        theme: 'light',
        percentile: 'p95',
        ci: 'ci70',
        ihcs: 'p95',
        substances: [],
        scaling: {
            wrapperCol: { md: {span: 12} },
            labelCol: { md: {span: 6} }
        },
        tailedScaling: {
            wrapperCol: {
                md: { offset: 6, span: 12 }
        }}
    })


    // rounding values
    const rounding = value => {
        if (!value) return 0;
        
        // higher than 10
        if (value >= 10) {
            return Math.round(value);
        }

        // higher than 1
        if (value >= 1) {
            return Math.round(value * 10) / 10;
        }

        // higher than 0.1
        if (value >= 0.1) {
            return Math.round(value * 100) / 100;
        }

        // lower
        let mystr = value.toString();
        
        // if scientific
        if (mystr.split('e-').length === 2) {
            // if too long
            if (mystr.length > 6) {
                let mystr1 = mystr.split('e')[0];
                let mystr2 = mystr.split('e')[1];
                return `${mystr1.split('.')[0]}e${mystr2}`
            }

            // otherwise
            return value
        }
        
        // if not scientific
        try {
            const regex = /[.][0]+/g;
            let myRes = regex.exec(mystr)[0].length + 1;
            let factor = 10**myRes;
            return Math.round(value * factor) / factor;
        }catch {
            return value;
        }
    }

   
    // API URL
    let API = 'https://ferihcs-api.com';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        API = 'http://localhost:8000';
    }
    
    

    // get headers for axios requests
    const headers = () => {
        return {
            Pragma: "no-cache",
            Authorization: `Token ${localStorage.getItem('feta-auth-token')}`
        }
    }

    
    // GET substances, scenarios...
    useEffect(() => {
        const company = (
            window.location.href.includes('taktics')
            ? 'takeda'
            : 'ferring'
        )


        setState({...state, loaded: false});
        const promises = [
            axios.get(`${API}/substances/`, { headers: headers() }),
            axios.get(`${API}/scenarios/`, { headers: headers() }),
            axios.get(`${API}/tasks/`, { headers: headers() }),
            axios.get(`${API}/settings/`, { headers: headers() }),
            axios.get(`${API}/get-role/`, { headers: headers() }),
            axios.get(`${API}/role-request/`, { headers: headers() }),
            axios.get(`${API}/get-my-site/`, { headers: headers() })
        ]

        Promise.all(promises)
        .then(res => {
            // oel list
            let oelList = [];
            if (res[3].data.company === 'ferring') {
                oelList = [
                    {
                        value: 'internal',
                        label: 'Ferring internal OEL'
                    }, {
                        value: 'national',
                        label: 'National OEL'
                    }, {
                        value: 'other',
                        label: 'Other'
                    }
                ]
            } else {
                //Takeda OEL list for substances
                oelList = [
                    {
                        value: 'takeda-oel',
                        label: 'Takeda Internal OEL'
                    }, {
                        value: 'ch-de-mak',
                        label: 'Switzerland and Germany - Maximale Arbeitsplatz-Konzentration (MAK)'
                    }, {
                        value: 'ireland-oelv',
                        label: 'Ireland - Occupational Exposure Limit Value (OELV)'
                    }, {
                        value: 'usa-tlv',
                        label: 'USA - Threshold Limit Value (TLV)'
                    }, {
                        value: 'usa-pel',
                        label: 'USA - Permissible Exposure Limit (PEL)'
                    }, {
                        value: 'eu-dnel',
                        label: 'Europe - Derived No Effect Level (DNEL)'
                    }, {
                        value: 'china-pc-twa',
                        label: 'China - Permissible Concentration-Time Weighed Average(PC-TWA)'
                    }, {
                        value: 'other',
                        label: 'Other'
                    }
                ]
            }



            
            setState({
                ...state,
                company: company,
                substances: res[0].data,
                scenarios: res[1].data,
                tasks: res[2].data,
                loaded: true,
                oelList: oelList,
                ...res[3].data,
                headers: headers(),
                role: res[4].data.role,
                roleObj: res[4].data,
                roleRequests: res[5].data,
                site: res[6].data
            })
        }).catch(() => {
            setState({
                ...state,
                company: company,
                loaded: true
            });
        })
    }, [update])


    const updateTheme = (theme) => {
        const newState = {...state, theme: theme};
        setState(newState);
    }

    if (!state.loaded) return <Spinner />

    return(
        <AppContext.Provider 
            value={{
                API: API,
                functions: {
                    rounding: rounding
                },
                data: state,
                updateTheme: updateTheme,
                update: ()=>runUpdate(update+1),
            }}>
            { props.children }
        </AppContext.Provider>
    )
}