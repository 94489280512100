import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';




export default function Spinner(props) {
    return(
        <div style={{ textAlign: "center",marginTop: 250 }}>
            <Space align="center" size="large">
                <span style={{ fontSize: 36, color: "yellow"}}>
                    <LoadingOutlined />
                </span>
                <span>{props.customLabel ? props.customLabel : 'please wait...' }</span>
            </Space>
        </div>
    )
}