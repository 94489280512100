import React, { useEffect } from 'react'
import { Bar }  from 'react-chartjs-2'


export default function MyChart(props) {
    let oel = props.oel;
    if (props.oelUnit !== 'ug/m3') {
        if (props.oelUnit === 'mg/m3') {
            oel *= 1000;
        } else {
            oel /= 1000;
        }
    }

    const data = {
        labels: ['', 'TWA', ...props.tasks.map((_, inx) => `Task ${inx+1}`), ''],
        datasets: [{
            label: "Exposure, ug/m3",
            data: ['', props.twa, ...props.tasks, ''],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(255, 206, 86, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1
        },
        {
            type: 'line',
            label: 'OEL',
            borderColor: 'rgb(200, 52, 100)',
            borderWidth: 2,
            fill: false,
            datasetFill: true,
            data: [ ...Array(props.tasks.length + 3).fill(oel) ]
        }]
    }
   
    return(
        <div style={{ marginTop: 25 }}>
           <Bar height={250} data={data} />
       </div>
    )
}