import { DeleteOutlined, EditOutlined, EnvironmentFilled, PlusOutlined } from '@ant-design/icons'
import { Alert, Divider, Empty, message, Popconfirm } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext'
import Spinner from './Spinner'
import ThemedButton from './ThemedButton'
import PageHeader from './PageHeader'


export default function ManagerPage() {

    const [state, setState] = useState({update: 0})
    const [searchedName, setSearchedName] = useState(false)
    const context = useContext(AppContext)
    const api = context.API
    const headers = context.data.headers

    // Fetch sites
    useEffect(() => {
        const promises = [
            axios.get(`${api}/sites/`, {headers}),
            axios.get(`${api}/get-site-managers/`, {headers})
        ]
        Promise.all(promises)
        .then (res => {
            setState({ 
                loaded: true,
                sites: res[0].data,
                managers: res[1].data,
                update: 0
            });
        })
        .catch(
            e => {
                console.log(e)
                setState({ loaded: true, error: true, update: 0 })
            }
        )
    }, [state.update])
 

    // While loading spin
    if (!state.loaded) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }


    // If error: display error alert
    if (state.error) {
        return(
            <div>
                <Alert
                    type="warning"
                    message="Failed to download"
                    description={<span>
                        Server did not send the site data. Please try again or, if this continue,
                            <Link to='/contact'>
                                <ThemedButton
                                    label="Contact support"
                                    type="link"
                                    style={{ color: 'blue', textDecoration: 'underline' }}
                                />
                            </Link>
                        </span>}
                    showIcon
                />
            </div>
        )
    }

    // else (i.e. loaded without errors)
    let sites = [...state.sites]
    const managers = state.managers

    if (searchedName) {
        sites = sites.filter(o => o.name.match(searchedName))
    }

    return (
        <div>
            <p style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center', border: '1px solid silver', padding: 10 }}>
                Before performing any actions, please get familiar with <Link to="/user-roles-info">the documentation</Link>
            </p>
            {
                context.data.roleRequests.length > 0 
                ? <div>
                    <Divider className={context.data.company}>Requests received</Divider>
                    <table>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Requested by</th>
                                <th>Date of request</th>
                                <th>Requested site name</th>
                                <th>Site location</th>
                                <th>Cover letter</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            context.data.roleRequests.map(
                                req => (
                                    <tr key={req.id}>
                                        <td>
                                            <div>
                                                <Popconfirm
                                                    placement='topRight'
                                                    title="Are you sure that you want to reject this request?"
                                                    onConfirm={() => {
                                                        axios.delete(`${api}/role-request/${req.id}/`, {headers: headers})
                                                        .then(() => {
                                                            message.warning("Request rejected");
                                                            context.update();
                                                        }).catch(() => message.error("Failed to reject"))
                                                    }}
                                                    okText="Yes, reject"
                                                    cancelText="No"
                                                >
                                                    <ThemedButton
                                                        type="primary"
                                                        label={<DeleteOutlined />} 
                                                    />
                                                </Popconfirm>

                                                <ThemedButton
                                                    type="success"
                                                    label="Approve"
                                                    onClick={() => {
                                                        axios.put(`${api}/role-request/${req.id}/`, 'manager',{headers: headers})
                                                        .then(() => {
                                                            message.success("Site approved and created");
                                                            context.update();
                                                        }).catch(() => message.error("Request failed"))
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>{req.user_email}</td>
                                        <td>{req.created}</td>
                                        <td>{req.name}</td>
                                        <td>{req.location}</td>
                                        <td>{req.message}</td>
                                    </tr>
                                )
                            )
                        }
                        </tbody>
                    </table>

                    <br/><br/>

                    <Divider className={context.data.company}>Created sites</Divider>
                </div>
                : <div />
            }

            <PageHeader
                buttonLabel={<span><PlusOutlined /> New Site</span>}
                searchPlaceholder="Site Name"
                link="/sites/site=0"
                onSearch={e => setSearchedName(e.target.value)}
                statisticsTitle="Sites"
                statisticsValue={sites.length}
                statisticsPrefix={<EnvironmentFilled />}
                statisticsSuffix="/100"
                searchDisabled={state.sites.length === 0}
                pageTitle="List of Sites"
            />

            {
                state.sites.length === 0 
                ? <Empty />
                : <table>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Site manager's email</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        sites.map(
                            site => (
                                <tr key={site.id}>
                                    <td>
                                        <Link to={`/sites/site=${site.id}/`}>
                                            <ThemedButton type="link" label={<EditOutlined />} />
                                        </Link>
                                        <Popconfirm
                                            title="Are you sure?"
                                            okText="Yes"
                                            onConfirm={()=>{
                                                axios.delete(`${api}/sites/${site.id}`, {headers})
                                                .then(
                                                    res => { 
                                                        message.warning("Site deleted :(");
                                                        setState({...state, update: 1});
                                                    }
                                                )
                                            }}
                                        >
                                            <ThemedButton type="link" label={<DeleteOutlined />} />
                                        </Popconfirm>
                                    </td>
                                    <td>{site.name}</td>
                                    <td>{site.location}</td>
                                    <td>{site.manager_email}</td>
                                </tr>
                            )
                        )
                    }</tbody>
                </table>
            }
        </div>
    )
}