import React, { useContext, useState } from 'react';
import Navigation from './Navigation';
import PageHeader from './PageHeader';
import { CalculatorOutlined, ClockCircleOutlined, ControlOutlined, DeleteOutlined, EditOutlined, PlusOutlined, PrinterOutlined, RotateLeftOutlined } from '@ant-design/icons';
import { AppContext } from '../AppContext';
import { Col, Empty, message, Popconfirm, Row, Space } from 'antd';
import ThemedButton from './ThemedButton';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';




/*
    Scenario list common for IH control strategies
    and exposure assessments

    Which are shown depends on the url
    * /exposure for exposure assessments
    * /ihcs for IH control strategies
*/

function ScenariosList() {
    
    const [ searchedName, setSearchedName ] = useState(); 
   
    // based on which we know which scenarios should be listed
    // scenario type of 1 is for exposure, 0 (default) for IH control strategies
    const location = useLocation();
    const scenarioType = location.pathname === '/exposure' ? 1 : 0;   
    
    // load scenarios from context based on their type
    const context = useContext(AppContext);
    const role = context.data.roleObj;
    const scenarios = context.data.scenarios.filter(
        o => o['scenario_type'] === scenarioType
    );
    let dataSource = scenarios.map(o => Object.assign({}, o));

    // Update 07-May-2022
    // Allow manager to see user emails (i.e. owners of scenarios)
    let isManager = false;
    if (context.data.loaded){
        isManager = context.data.role === 'manager';
    }
    let columns = ['ID', 'Actions', 'Name','Owner','Substance', 'Tasks'];
    if (isManager){
        columns = ['ID', 'Actions', 'Name','Owner','Created by','Substance', 'Tasks']
    }

    dataSource = (
        searchedName
        ? [...dataSource.filter(sub => sub.name.match(searchedName))] 
        : [...dataSource]
    );


    
    // delete scenario by id
    const handleDelete = id => {
        axios.delete(
            `${context.API}/scenarios/${id}`,
            {headers: {...context.data.headers}}
            ).then(() => {
                message.warning("Scenario deleted");
                context.update();
            }).catch(() => message.error("ERROR OCCURED"))
    }

    // Update 07-Apr-2022
    // PATCH to avoid blanc screen when scenario is public (site) but substance is not
    const getSubstanceName = (subId) => {
        try {
            return (context.data.substances
                            .find(item => item.id === subId)
                            .name
            )
        } catch (e) {
            console.log('Substance name not found');
            return 'Private*'
        }
    }




    // variables based on scenario type
    const navTitle = scenarioType === 0 ? "IH Control Strategies" : "Exposure Assessment"
    const scenarioLink = `/scenario/stype=${scenarioType}-es=0`;


    // PATCH: 11-Jun-2022: sorting alphabetically
    dataSource.sort(function(a, b) {
        let textA = a.name.toUpperCase();
        let textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return(
        <div>
            <Navigation title="Exposure Scenarios" subTitle={navTitle}/>
            <PageHeader
                buttonLabel={<span><PlusOutlined /> New Scenario </span>}
                searchPlaceholder="Scenario Name"
                link={scenarioLink}
                searchDisabled={scenarios.length === 0}
                onSearch={e => setSearchedName(e.target.value)} 
                statisticsTitle="Exposure Scenarios"
                statisticsValue={scenarios.length}
                statisticsPrefix={<ControlOutlined />}
                statisticsSuffix="/1000"
                pageTitle={scenarioType === 0 ? "IH Control Strategies" : "Exposure Assessment"}
            />
            <main>
                {
                    scenarios.length === 0 
                    ? <Empty description="No Scenarios"/>
                    : <table>
                        <thead>
                            <tr>
                                {columns.map(
                                    th => <th key={th}>{th}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.map((scenario, inx) => (
                                <tr key={scenario.id}>
                                    <td>{scenario.id}</td>
                                    <td width={scenarioType===1 ? 290 : 150}>
                                        <Space>
                                            {
                                                scenarioType === 0 
                                                ? <div />
                                                : <Link to={`/exposure-estimates/es=${scenario.id}`}>
                                                    <ThemedButton 
                                                        type="default"
                                                        label={<span style={{ fontSize: 12 }}><CalculatorOutlined /> Model exposure</span>}
                                                    />
                                                </Link>
                                            }
                                            
                                            {
                                                getSubstanceName(scenario.substance) === 'Private*' ?
                                                <div />
                                                : <Link to={`/scenario/stype=${scenarioType}-es=${scenario.id}`}>
                                                    <ThemedButton 
                                                        type="link"
                                                        label={<span><EditOutlined /></span>}
                                                    />
                                                </Link>
                                            }

                                            <Link to={`/report/es=${scenario.id}`}>
                                                <ThemedButton
                                                    label={<span><PrinterOutlined /></span>}
                                                    type="link"
                                                />
                                            </Link>
                                            {
                                                role.user === scenario.user
                                                ? <Popconfirm 
                                                    title="Are you sure?"
                                                    okText="Yes"
                                                    onConfirm={()=>handleDelete(scenario.id)}
                                                >
                                                    <DeleteOutlined />
                                                </Popconfirm>
                                                :<div />
                                            }
                                        </Space>
                                    </td>
                                    <td>{scenario.name}</td>
                                    <td>{scenario.private === true ? 'User': <span style={{ color: 'red' }}>Site</span>}</td>
                                    {
                                        isManager ?
                                        <td>
                                            {scenario.user_email}<br/>
                                            <span style={{ color: 'red' }}>{scenario.user_site}</span>
                                        </td>
                                        : ""
                                    }
                                    <td>{scenario.substance ? 
                                            getSubstanceName(scenario.substance)
                                            : "Not specified"
                                        }
                                    </td>
                                    <td width={scenarioType===1 ? 450 : 520}>
                                    {
                                        context.data.tasks.filter(o => o.scenario === scenario.id).map(
                                            task => (
                                                <Row gutter={16} justify='start' key={task.id} style={{ marginBottom: 2 }}>
                                                    <Col span={scenarioType === 0 ? 8 : 14} style={{
                                                        borderLeft: '3px solid rgba(77,77,77,0.2)',
                                                        fontSize: 12
                                                    }}>{ task.name }</Col>
                                                    {
                                                        scenarioType === 0 ?
                                                        <Col span="5"><span style={{ fontSize: 12 }}><ClockCircleOutlined /> { task.duration }min</span></Col>
                                                        : <Col><span style={{ fontSize: 12 }}><ClockCircleOutlined /> { task.duration }min</span></Col>
                                                    }
                                                    {
                                                        scenarioType === 0
                                                        ? <Col span="11">
                                                            <Link to={`/controls/es=${scenario.id}-task=${task.id}`}>
                                                                <ThemedButton
                                                                    type='link'
                                                                    label={
                                                                        <span
                                                                            style={{ color: "green" }}>
                                                                                <ControlOutlined 
                                                                                    style={{ color: "green" }}/> Control Strategies
                                                                        </span>} 
                                                                />
                                                            </Link>
                                                        </Col>
                                                        : ""
                                                    }
                                                </Row>
                                            )
                                        )
                                    }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </main>
            
            <div style={{ marginTop: 50 }}>
                * <i>
                    Private data = This data is hidden because 
                    the owner made it private (i.e. not available to other site users).
                    Please contact the owner (either site manager or another site member)
                    to change the privacy data for this data instance.
                </i>
            </div>
        </div>
    )
}
export default ScenariosList;