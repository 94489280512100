import { Alert, Col, Form, Input, Row } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext'
import ThemedButton from './ThemedButton'




/*
    Upgrade: March 2022
    Requested via Upwork: Separate contracts with Takeda and Ferring
    Contact form as part of a one-year maintainance
*/
export default function Contact () {

    const [ state, setState ] = useState({ alert: 0 });

    const scaling = {
        wrapperCol: { xs: {span:24}, md: {span: 8} },
        labelCol: { xs: {span: 24}, md: {span: 4, offset: 4} }
    }
    const context = useContext(AppContext);

    const myNavigation = (
        <nav>
            <Row justify='space-between'>
                <Col>
                    <span style={{ color: 'darkgray' }}><strong>Troubleshooting</strong></span>
                </Col>
                <Col>
                    <Link to="/">
                        <ThemedButton type="link" label="<< Cancel (go back)" style={{ color: '#1890ff' }}/>
                    </Link>
                </Col>
            </Row>
        </nav>
    )

    // if state is not zero -> show alert
    if (Math.abs(state.alert) === 1) {
        return (
            <div>
                <div>{ myNavigation }</div>
                {   
                    state.alert === -1 ?
                    <Alert type='error' message="Error occured! Please contact directly admin@taktics.app" />
                    :<Alert type='success' message="You message has been send to the technical support. THANK YOU! (Your message will be responded within 12h)" />
                }
            </div>
        )
    }

    return (
        <div>
            <div>{ myNavigation }</div>

            <Form
                name="contact"
                initialValues={{}}
                { ...scaling }
                style={{ marginTop: 50, padding: 10 }}
                onFinish = {(values) => {
                    console.log("test")
                    axios.post(`${context.API}/contact/`, values)
                    .then(res => res.status === 201 ? setState({alert: 1}) : setState({alert: -1}) )
                    .catch( () =>  setState({ alert: -1 }))
                }}
            >
                <Form.Item wrapperCol={{ md: {offset: 8} }}>
                    <div style={{ fontSize: 20 }}>Contact Form</div>
                </Form.Item>

                <Form.Item 
                    name="email"
                    label="e-mail"
                    required
                    rules={ [{required: true, message: "Email is required"}, { type: 'email', message: 'Not email' }] }
                >
                    <input type="email" />
                </Form.Item>

                <Form.Item
                    name="title"
                    label="Title"
                    required
                    rules={ [{required: true, message: "Title is required"}] }
                >
                    <input type="text" />
                </Form.Item>

                <Form.Item
                    name="message"
                    label="Message"
                    required
                    rules={ [{required: true, message: "Message is required"}] }
                >
                    <textarea rows={5} />
                </Form.Item>

                <Form.Item wrapperCol={{ md: {offset: 8} }}>
                    <ThemedButton
                        buttonType='submit'
                        type="primary"
                        label="Send"
                        style={{ width: 100 }}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}