import { 
    CalculatorOutlined,
    ControlOutlined,
    ExperimentOutlined,
    HomeOutlined, 
    LogoutOutlined,
    MailOutlined,
    SettingOutlined
} from '@ant-design/icons';
import { Row, Col, Divider, Popconfirm, Badge } from 'antd';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';



// general navigation
export default function Navigation(props) {
    const context = useContext(AppContext);
    let history = useHistory();

    let right = (
        <div>
            {
                props.customNavigation
                ? <Divider
                    style={{ marginLeft: 0 }}
                    className={context.data.company}
                    type="vertical" 
                />
                : ''
            }
            <Link to="/">
                <ThemedButton
                    type="link"
                    label={<span><HomeOutlined /> Home</span>}
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />
            <Link to="/substances">
                <ThemedButton
                    type="link"
                    label={<span><ExperimentOutlined /> Substances</span>}
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />
            <Link to="/ihcs">
                <ThemedButton
                    type="link"
                    label={<span><ControlOutlined /> IH Control Strategies</span>}
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />
            <Link to="/exposure">
                <ThemedButton
                    type="link"
                    label={<span><CalculatorOutlined /> Exposure Assessment</span>}
                />
            </Link>
            <Divider className={context.data.company} type="vertical" />
            <Link to="/settings">
                <ThemedButton
                    type="link"
                    label={<span><SettingOutlined /> Settings</span>}
                />
            </Link>
        </div>
    )

    // PATCH 04-MAY-2021
    // Preserves right in main
    // Allows the main menu to appear above all
    // While in form page for example
    const main = right;


    if (props.settingsOnly) {
        right = (
            <div>
                <Link to="/contact">
                    <ThemedButton
                        type="link"
                        label={<span><MailOutlined /> Contact Support</span>}
                    />
                </Link>
                <Divider type="vertical" className={context.data.company} style={{ marginLeft: 20 }} />
                <Link to="/settings">
                    <ThemedButton
                        type="link"
                        label={<span><SettingOutlined /> Settings</span>}
                    />
                </Link>
                <Divider type="vertical" className={context.data.company} />
                <Popconfirm
                    title="Are you sure that you really want to logout from this amazing software?"
                    okText="I am sorry, but yes"
                    cancelText="No no!"
                    onConfirm={() => {
                        localStorage.removeItem('feta-auth-token');
                        history.push('/login');
                    }}
                >
                    <ThemedButton
                        type="link"
                        label={<span><LogoutOutlined /> Logout</span>}
                    />
                </Popconfirm>
            </div>
        )
    }

    if (props.customNavigation) right = props.customNavigation;

    return(
        <div>
            {
                props.customNavigation
                ? <div style={{
                    marginBottom: 10,
                    paddingBottom: 5,
                    borderBottom: '1px dashed rgba(200,200,200,0.2)'
                }}>
                    { main }
                </div>
                : <div />
            }

            <nav>
                <Row justify="space-between" align="middle">
                    <Col md={8}>
                        {
                            props.settingsOnly && props.homeLink
                            ? <div>
                                <Link to="/">
                                    <ThemedButton
                                        type="link"
                                        label={<span><HomeOutlined /> Home</span>}
                                    />
                                </Link>
                            </div>
                            :<div>
                                <strong>{props.title}</strong>
                                <Divider className={context.data.company} type="vertical" />
                                <span>{props.subTitle}</span>
                            </div>
                        }
                    </Col>

                    <Col md={16}>
                        <div style={{ textAlign: "right" }}>{ right }</div>
                    </Col>
                </Row>
            </nav>
        </div>
    )
}