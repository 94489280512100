import { Divider, message, Space } from 'antd';
import React, { useContext, useState } from 'react';
import Navigation from './Navigation';
import { Form } from 'antd';
import { AppContext } from '../AppContext';
import ThemedButton from './ThemedButton';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';


function Settings() {
    const context = useContext(AppContext);
    const [theme, setTheme] = useState(context.data.theme);
    const [ form ] = Form.useForm();
    let history = useHistory();




    return(
        <div>
            <Navigation 
                title="Settings" 
                subTitle="IHCSoftware settings"
                customNavigation={
                    <div>
                        <ThemedButton
                            type="link-outlined"
                            label={<span><CloseOutlined /> Close</span>}
                            onClick={() => history.push('/')}
                        />
                        <Divider className={context.data.company} type="vertical" />
                        <ThemedButton
                            type="primary"
                            label={<span><SaveOutlined /> Save</span>}
                            onClick={form.submit}
                        />
                    </div>
                }
            />

            <main>
                <Form 
                    {...context.data.scaling}
                    labelAlign="left"
                    form={ form }
                    initialValues={{
                        percentile: context.data.percentile,
                        ci: context.data.ci,
                        theme: context.data.theme
                    }}
                    onFinish={values => {
                        let data = {...values, theme: theme};
                        axios.put(
                            `${context.API}/settings/`,
                            data,
                            {headers: context.data.headers}
                        ).then(
                            () => {
                                message.success("Settings updated");
                                context.update();
                            }
                        ).catch(
                            () => message.error("ERROR OCCURED")
                        )
                    }}
                >
                    <Divider className={context.data.company} type="horizontal">Theme</Divider>
                    <Form.Item
                        name="theme"
                        label="Dark theme on" 
                    >
                       <Space>
                            <input 
                                type="checkbox"
                                onChange={e => setTheme(e.target.checked ? 'dark' : 'light')}
                                defaultChecked={theme === 'dark'}
                            /> 
                            <label className="checkbox-label">
                                {theme === 'dark' ? 'Dark theme' : 'Light theme'}
                            </label>
                       </Space>
                    </Form.Item>
                    <br/><br/>

                    
                


                    <Divider className={context.data.company} type="horizontal">Exposure assessment</Divider>
                    <Form.Item label="Preferred percentile" name="percentile">
                        <select>
                            <option value="p50">50th (median)</option>
                            <option value="p75">75th</option>
                            <option value="p90">90th</option>
                            <option value="p95">95th (recommended)</option>
                            <option value="p99">99th</option>
                        </select>
                    </Form.Item>

                    <Form.Item label="Preferred confidence interval" name="ci">
                        <select>
                            <option value="ci70">70%</option>
                            <option value="iq">75% (inter-quartile)</option>
                            <option value="ci80">80%</option>
                            <option value="ci90">90%</option>
                            <option value="ci95">95%</option>
                        </select>
                    </Form.Item>
                </Form>
            </main>
        </div>
    )
}
export default Settings;